import React from "react"

const ministerioContext = React.createContext([{
  educacion:[{
    data:{
    labels: ["Programa Central","Programa Sustantivo", "Partidas no asignables a Programas"],
    datasets: [
      {
        data: [ 71.71  ,10.76,  17.53],
        backgroundColor: ["orange", "#FAFF0E",'blue'],
        hoverBackgroundColor: ["orange", "#FAFF0E",'blue']
      }
    ]
  }},
  {items: [{id:1,descripcion:"Educación inicial (per-jardín y jardín) con Becas, Complemento Nutricional, Canasta Básica de Útiles y otros.", },
    { id: 2, descripcion: "Educación inicial (per-jardín y jardín) con Becas, Complemento Nutricional, Canasta Básica de Útiles y otros.", },
    { id: 3, descripcion: "Educación Inicial ampliada a niños y niñas desde cero a cinco años. ", },
    { id: 4, descripcion: "Textos para estudiantes y guías para Docentes de la Educación Media", },
    { id: 5, descripcion: "Documentos pedagogicos para estudiantes con necesidades de apoyo", },
    { id: 6, descripcion: "Material educativo diseñado para pueblos indígenas", },
    { id: 7, descripcion: "Distribución de materiales didáctico a las instituciones educativas", },
    { id: 8, descripcion: "Evaluaciones estandarizadas sobre el desempeño de los estudiantes", },
    { id: 9, descripcion: "Ampliación de la jornada escolar", },
    { id: 10, descripcion: "Becas, kits escolares para la culminación oportuna de estudios", },
    { id: 11, descripcion: "Boleto estudiantil para la culminación oportuna de estudios  " },
  
  
  ]},{
  datos:{
    nombre:"Ministerio de Educación y Ciencias",
    slug:'/educacion',
    programas:[{
      nombre:"Programa Central",
      monto: "G. 6.628.473.513.765",
      
    },{
      nombre:"Misional",
      monto:"G. 5.783.387.122.583",
      aprobado: 5783299581414
    },{
      nombre:"Administrativo",
      monto:"G. 825.610.338.799",
      aprobado: 836379056737
    },
    {
      nombre:"Proyecto",
      monto:"G. 19.476.052.383 ",
      aprobado: 19476052383
    },{
      nombre:"Programa sustantivo",
      monto:"G. 994.908.680.081"
    },{
      nombre:"Proyecto.",
      monto:"G. 766.478.517.916",
      aprobado:766478517916
    },{
      nombre:"Actividad",
      monto:"G. 228.430.162.165",
      aprobado:203421992715
    },
    {
      nombre:"Partidas no asignables a Programas",
      monto:"G. 1.620.564.757.342",
      aprobado:1683135483525
    }
  ],
  presupuestoTotal: "G. 9.243.946.951.188",
  },
 
    
  },{
    treeMapData : {
      'name':"MINISTERIO DE EDUCACIÓN Y CIENCIAS",
      'children':
      [{
      "name":"Programa Central",
      "children": [
                  { 
                      "name": "Actividad Administrativa" ,
                      "children": [
                          {"name":"GESTIÓN ADMINISTRATIVA INSTITUCIONAL","value":  825610338799,"aprobado": 836379056737 },
                          
                      ]
                  },
                  {
                      "name":"Actividad Misional",
                      "children": [ 
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN EDUCACIÓN INICIAL","value":  23733554561,"aprobado": 23733554561 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN PRIMER Y SEGUNDO CICLO DE EEB","value":  3788337220084,"aprobado": 3788333296380 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN EL TERCER CICLO DE LA EEB Y EM","value":  1446246278653,"aprobado": 1446243447232},
                          {"name":"ATENCION A EDUCACION SUPERIOR Y CIENCIAS","value":  55655897369,"aprobado": 55625750280 },
                          {"name":"FORMACION DE MUSICOS EN LOS DISTINTOS GENEROS DE MUSICA","value":  15378406129,"aprobado": 15378365148 },
                          {"name":"BRINDAR SERV.EDUC.A JOVENES Y ADULTOS DE 15 AÑOS Y MAS","value":  335486421492,"aprobado": 335454796853 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN CENTROS REGIONALES DE EDUC","value":  80117516114,"aprobado": 80117516114 },
                          {"name":"SERV.EDUC.A ESTUDIANTES C/NECESIDADES ESPECIFICAS DE APOYO","value":  18766116131,"aprobado": 18766116131 },
                          {"name":"PROMOVER EL TALENTO Y LA FORMACION ARTISTICA","value":  964526200,"aprobado": 964526200 },
                          {"name":"BRINDAR ATENCION A INSTITUCIONES DE EDUC ESCOLAR INDIGENA","value":  4937942157,"aprobado": 4936142157},
                          {"name":"OTORGAR BECAS PARA EDUCACION SUPERIOR","value":  7837900000,"aprobado": 7837900000 },
                          {"name":"PLANIFICACION Y MONITOREO DE LA POLITICA EDUCATIVA NACIONAL","value":  377987871,"aprobado": 375796588 },
                          {"name":"DIFUNDIR LA INVESTIGACION EDUCATIVA","value":61750000,"aprobado": 61750000 },
                          {"name":"EVALUAR LA CALIDAD EDUCATIVA","value":3419909678,"aprobado": 3417954398 },
                          {"name":"PROMOVER POLITICAS P/ EL DESARROLLO DE EDUCACION Y CIENCIAS","value":  2065696144,"aprobado": 2052669372 }
                         
  
                          
                      ]
                  },
                  {
                      "name":"Proyecto",
                      "children": [
                          {"name":"FOMENTO Y APOYO OPERAT. PARA LA EJEC. DE PROY. DEL MEC","value":7840145062,"aprobado": 7840145062 },  
                          {"name":"DIFUSIÓN DISEÑO DE LA ESTRATEGIA DE TRANSFORMACIÓN EDUC","value":  11635907321,"aprobado": 11635907321 }, 
                      ]
                  },
                 
              ]
  }
      ,{
    "name":"Programa Sustantivo",
    "children": [
                { 
                    "name": "ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN" ,
                    "children": [
                        {"name":"OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","value":  73140554894,"aprobado":  62736880163 },
                        {"name":"AMPLIACIÓN EN LA ATENC. EDUC. DESDE LA GEST. HASTA 5 AÑOS","value":123596276913 ,"aprobado":   123596276913 },
                    ]
                },
                {
                    "name":"EDUCACIÓN MEDIA INTEGRADA",
                    "children": [
                        {"name":"DIFUNDIR LAS OFERTAS EDUCATIVAS","value":  200000000,"aprobado":   200000000 },
                        {"name":"TRANSFERENCIA Y DOTACION TEXTOS A ESTUD.Y GUIAS A DOCENTES","value":32000000,"aprobado":   32000000 },
                        {"name":"CAPACITACIÓN A LOS EDUCADORES DE TODOS LOS NIVELES Y MODAL.","value":  85187652000,"aprobado":   85187652000 }
                        
                    ]
                },
                {
                    "name":"CALIDAD DEL APRENDIZAJE",
                    "children": [
                        {"name":"ELABORAR DOCUMENTOS PEDAG  P/ ESTUD  C/ NECESIDADES DE APOYO","value":156753244,"aprobado":  150483114 
                      },
                        {"name":"ELABORAR CURRÍCULUM Y MATERIAL EDUCATIVO P/ PUEBLO INDÍGENA","value":238349364,"aprobado":   236450044 
                      },
                        {"name":"DOTAR A INSTITUCIONES EDUCATIVAS DE MATERIALES DIDÁCTICOS","value":1510309674,"aprobado":   1510309674 
                      },
                        {"name":"INVESTIGACIÓN EVAL. ESTANDARIZADAS DE DESEMPEÑO A ESTUD.","value":3520539526,"aprobado":  3520539526 
                      },
                        {"name":"CAPACITACIÓN APOYO A LA AMPLIACIÓN DE LA JORNADA ESCOLAR.","value":  20584812599,"aprobado":  20584812599 
                      },
                        {"name":"MEJORAMIENTO DE COND. DE APRENDIZ. CON INCORPORACIÓN DE TIC.","value":    322565734094,"aprobado":   322565734094 
                      }
                        
                    ]
                },
                {
                    "name":"CULMINACIÓN OPORTUNA DE ESTUDIOS",
                    "children": [
                        {"name":"OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","value":  153184194989,"aprobado":   138587869720 },
                        {"name":"AMPLIACIÓN REP  111 EST. EDUC  ASU Y 10 DEP  GEOG ","value":    4424756667,"aprobado":   4424756667 },
                        {"name":"AMPLIACIÓN REP  822 EST  EDUC  ASU 17 DPTOS","value":    200210262217 ,"aprobado":   200210262217 },
                        {"name":"MEJORAMIENTO CONDICIONES DE TRANSPORTE DE ESTUDIANTES","value":  6356483900 ,"aprobado":   6356483900 }
                    ]
                }
            ]
  },{
    "name":"Partidas no asignables a Programas",
    "children": [
      {"name":"Transferencias Consolidables","value":1620564757342,"aprobado":  1683135483525 
    }
    ]
      
  }]}},{
    treeMapDataAprobado : {
      'name':"MINISTERIO DE EDUCACIÓN Y CIENCIAS",
      'children':
      [{
      "name":"Programa Central",
      "children": [
                  { 
                      "name": "Actividad Administrativa" ,
                      "children": [
                          {"name":"GESTIÓN ADMINISTRATIVA INSTITUCIONAL","aprobado":  825610338799,"value": 836379056737 },
                          
                      ]
                  },
                  {
                      "name":"Actividad Misional",
                      "children": [ 
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN EDUCACIÓN INICIAL","aprobado":  23733554561,"value": 23733554561 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN PRIMER Y SEGUNDO CICLO DE EEB","aprobado":  3788337220084,"value": 3788333296380 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN EL TERCER CICLO DE LA EEB Y EM","aprobado":  1446246278653,"value": 1446243447232},
                          {"name":"ATENCION A EDUCACION SUPERIOR Y CIENCIAS","aprobado":  55655897369,"value": 55625750280 },
                          {"name":"FORMACION DE MUSICOS EN LOS DISTINTOS GENEROS DE MUSICA","aprobado":  15378406129,"value": 15378365148 },
                          {"name":"BRINDAR SERV.EDUC.A JOVENES Y ADULTOS DE 15 AÑOS Y MAS","aprobado":  335486421492,"value": 335454796853 },
                          {"name":"BRINDAR SERVICIO EDUCATIVO EN CENTROS REGIONALES DE EDUC","aprobado":  80117516114,"value": 80117516114 },
                          {"name":"SERV.EDUC.A ESTUDIANTES C/NECESIDADES ESPECIFICAS DE APOYO","aprobado":  18766116131,"value": 18766116131 },
                          {"name":"PROMOVER EL TALENTO Y LA FORMACION ARTISTICA","aprobado":  964526200,"value": 964526200 },
                          {"name":"BRINDAR ATENCION A INSTITUCIONES DE EDUC ESCOLAR INDIGENA","aprobado":  4937942157,"value": 4936142157},
                          {"name":"OTORGAR BECAS PARA EDUCACION SUPERIOR","aprobado":  7837900000,"value": 7837900000 },
                          {"name":"PLANIFICACION Y MONITOREO DE LA POLITICA EDUCATIVA NACIONAL","aprobado":  377987871,"value": 375796588 },
                          {"name":"DIFUNDIR LA INVESTIGACION EDUCATIVA","aprobado":61750000,"value": 61750000 },
                          {"name":"EVALUAR LA CALIDAD EDUCATIVA","aprobado":3419909678,"value": 3417954398 },
                          {"name":"PROMOVER POLITICAS P/ EL DESARROLLO DE EDUCACION Y CIENCIAS","aprobado":  2065696144,"value": 2052669372 }
                         
  
                          
                      ]
                  },
                  {
                      "name":"Proyecto",
                      "children": [
                          {"name":"FOMENTO Y APOYO OPERAT. PARA LA EJEC. DE PROY. DEL MEC","aprobado":7840145062,"value": 7840145062 },  
                          {"name":"DIFUSIÓN DISEÑO DE LA ESTRATEGIA DE TRANSFORMACIÓN EDUC","aprobado":  11635907321,"value": 11635907321 }, 
                      ]
                  },
                 
              ]
  }
      ,{
    "name":"Programa Sustantivo",
    "children": [
                { 
                    "name": "ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN" ,
                    "children": [
                        {"name":"OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","aprobado":  73140554894,"value":  62736880163 },
                        {"name":"AMPLIACIÓN EN LA ATENC. EDUC. DESDE LA GEST. HASTA 5 AÑOS","aprobado":123596276913 ,"value":   123596276913 },
                    ]
                },
                {
                    "name":"EDUCACIÓN MEDIA INTEGRADA",
                    "children": [
                        {"name":"DIFUNDIR LAS OFERTAS EDUCATIVAS","aprobado":  200000000,"value":   200000000 },
                        {"name":"TRANSFERENCIA Y DOTACION TEXTOS A ESTUD.Y GUIAS A DOCENTES","aprobado":32000000,"value":   32000000 },
                        {"name":"CAPACITACIÓN A LOS EDUCADORES DE TODOS LOS NIVELES Y MODAL.","aprobado":  85187652000,"value":   85187652000 }
                        
                    ]
                },
                {
                    "name":"CALIDAD DEL APRENDIZAJE",
                    "children": [
                        {"name":"ELABORAR DOCUMENTOS PEDAG. P/ ESTUD. C/ NECESIDADES DE APOYO","aprobado":156753244,"value":  150483114 
                      },
                        {"name":"ELABORAR CURRÍCULUM Y MATERIAL EDUCATIVO P/ PUEBLO INDÍGENA","aprobado":238349364,"value":   236450044 
                      },
                        {"name":"DOTAR A INSTITUCIONES EDUCATIVAS DE MATERIALES DIDÁCTICOS","aprobado":1510309674,"value":   1510309674 
                      },
                        {"name":"INVESTIGACIÓN EVAL. ESTANDARIZADAS DE DESEMPEÑO A ESTUD.","aprobado":3520539526,"value":  3520539526 
                      },
                        {"name":"CAPACITACIÓN APOYO A LA AMPLIACIÓN DE LA JORNADA ESCOLAR.","aprobado":  20584812599,"value":  20584812599 
                      },
                        {"name":"MEJORAMIENTO DE COND. DE APRENDIZ. CON INCORPORACIÓN DE TIC.","aprobado":    322565734094,"value":   322565734094 
                      }
                        
                    ]
                },
                {
                    "name":"CULMINACIÓN OPORTUNA DE ESTUDIOS",
                    "children": [
                        {"name":"OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","aprobado":  153184194989,"value":   138587869720 },
                        {"name":"AMPLIACIÓN REP. 111 EST. EDUC. ASU Y 10 DEP. GEOG.","aprobado":    4424756667,"value":   4424756667 },
                        {"name":"AMPLIACIÓN REP. 822 EST. EDUC. ASU 17 DPTOS","aprobado":    200210262217 ,"value":   200210262217 },
                        {"name":"MEJORAMIENTO CONDICIONES DE TRANSPORTE DE ESTUDIANTES","aprobado":  6356483900 ,"value":   6356483900 }
                    ]
                }
            ]
  },{
    "name":"Partidas no asignables a Programas",
    "children": [
      {"name":"Transferencias Consolidables","aprobado":1620564757342,"value":  1683135483525 
    }
    ]
      
  }]}}
  ,{resumen:[
      {
        nombre:"ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN",
        porcentaje:"41.38"
      }, {
        nombre:"EDUCACIÓN MEDIA INTEGRADA",
        porcentaje:" 0.010"
      },
      {
        nombre:"CALIDAD DEL APRENDIZAJE",
        porcentaje:"14.28"
      },
      {
        nombre:"CULMINACIÓN OPORTUNA DE ESTUDIOS",
        porcentaje:"44"
      },
]},
[

]
  ],
}, {
  obras:[{
    data:{
    labels: ["Programa Central","Programa Sustantivo", "Partidas no asignables a Programas"],
    datasets: [
      {
        data: [   20.05, 78.93 , 1.03],
        backgroundColor: ["orange", "#FAFF0E",'blue'],
        hoverBackgroundColor: ["orange", "#FAFF0E",'blue']
      }
    ]
  }},
  {items: [
    "Aumento y mejoramiento de redes viales pavimentadas.",
    
    "Mejoramiento de caminos vecinales y puentes rurales ",
    "Suministro continuo y permanente para la gente y mejoramiento de los servicios de alcantarillado  "
  
  
  ]},{
  datos:{
    nombre:"MINISTERIO DE OBRAS PÚBLICAS Y COMUNICACIONES",
    slug:'/obras',
    programas:[{
      nombre:"Programa Central",
      monto: "G. 1.051.526.014.476 "
    },{
      nombre:"Misional",
      monto:"G. 159.006.986.699",
      aprobado: 153636513837
    },{
      nombre:"Administrativo",
      monto:"G.  371.512.289.231",
      aprobado: 368446533000
    },
    {
      nombre:"Proyecto",
      monto:"G. 521.006.738.546 ",
      aprobado: 482393504006
    },{
      nombre:"Programa sustantivo",
      monto:"G. 4.140.503.123.340"
    },
    {
      nombre:"Proyecto.",
      monto:"G. 4.140.503.123.340",
      aprobado: 3956225469221
    },{
      nombre:"Actividad",
      monto:"G. 0",
      aprobado: 0
    },{
      nombre:"Partidas no asignables a Programas",
      monto:"G. 53.774.694.697",
      aprobado: 53752688697
    }
  ],
  presupuestoTotal: "G. 5.245.803.832.513",
  },
 
    
  },{
    treeMapData : {
      'name':"MINISTERIO DE OBRAS  PÚBLICAS Y COMUNICACIONES",
      'children':
      [{
      "name":"Programa Central",
      "children": [
                  { 
                      "name": "Actividades Administrativas" ,
                      "children": [
                          {"name":"GESTION ADMINISTRATIVA FINANCIERA","value":  371512289231, "aprobado": 368446533000   },
                          
                      ]
                  },
                  {
                      "name":"Actividad Misional",
                      "children": [
                          {"name":"REGULACION DEL TRANSPORTE PÚBLICO DEL ÁREA METROPOLITANA","value":34816987000, "aprobado": 34816987000  },
                          {"name":"CONTROL DEL TRANSPORTE PÚBLICO DEL ÁREA METROPOLITANA","value":  18799845549, "aprobado": 18799845549   },
                          {"name":"FISCALIZACIONES DE LOS RECURSOS MINERALES Y ENERGETICOS","value":  6837994933, "aprobado": 6837994933   },
                          {"name":"CONTROL DE CUMPLIMIENTO DE LAS NORMAS DE TRANSITO","value":  51422170537, "aprobado": 51421603210   },
                          {"name":"FISCALIZACIONES DEL TRANSPORTE FLUVIO-MARITIMO","value":  2667336228, "aprobado": 2661840003   },
                          {"name":"REGULACION DEL TRANSPORTE FLUVIO-MARITIMO","value":  2233095912, "aprobado": 2226739524  },
                          {"name":"MANTENIMIENTO, CONSERVACION DE PATRIMONIO HISTORICO Y OTROS","value":  3579000000, "aprobado":  3567600000  },
                          {"name":"CONSERVACIÓN Y DESPEJE DE LA CONTAMINACIÓN DEL RIO PILCOMAYO","value":  9842768360, "aprobado": 5915284528   },
                          {"name":"MANTENIMIENTO DE MÁRGENES Y CAUCES DE ARROYOS","value":  28807788180, "aprobado":  15388619090 },
                          {"name":"RESTAURACIÓN  TRAMO DE LA VIA FERREA - VILLA INGLESA","value":  0, "aprobado":  12000000000 }
                             
                      ]
                  },
                  {
                      "name":"Proyecto",
                      "children": [
                          {"name":"SP CONST. AVDA. COST. CENT. HIST. Y PARQUE BICENTENARIO","value":14447423400, "aprobado": 14425003400  },
                          {"name":"CONSTRUCCIÓN AV. COSTANERA NORTE, 2ª ETAPA","value":  167130374717, "aprobado": 164035339273  }, 
                          {"name":"DIAGNÓSTICO Y PROTOCOLO DE INTERVENCIÒN EDIF. HISTÒRICOS","value":1700000000, "aprobado": 1445000000 }, 
                          {"name":"RESTAURACIÓN DE EDIFICIOS PATRIMONIALES","value":  16061828100, "aprobado": 15911828100  }, 
                          {"name":"EQUIPAMIENTO PROVISIÓN Y COLOCACIÓN DE TEATROS","value":3100000000, "aprobado": 3100000000  }, 
                          {"name":"LEVANTAMIENTO DE DATOS Y ELABORACIÓN DE PROYECTOS EJECUTIVOS","value":  1420000000, "aprobado":710000000   }, 
                          {"name":"CONSTRUCCIÓN PENITENCIARÍA REGIONAL DE CIUDAD DEL ESTE","value":  10000000000, "aprobado": 0   }, 
                          {"name":"CONSTRUCCIÓN DEL CIRS DE EMBOSCADA","value":  15000000000, "aprobado": 15000000000   }, 
                          {"name":"SP REC. Z. PTO AS. CENT OFIC, CONT. P2419/OC-PR Y 2420/BL-PR","value":  240981743440, "aprobado": 229350964344   }, 
                          {"name":"ANÁLISIS ELAB.PLAN MAESTRO DE NAVEGACIÓN DEL RIO PY","value":  12000000000, "aprobado": 0  }, 
                          {"name":"CAPACITACIÓN Y MEJOR.FORM EJECUCIÓN DE PROYECTOS DE LA DIPE","value":  10000000000, "aprobado": 9250000000   }, 
                          {"name":"CONSTRUCCIÓN CONSTRUCCIÓN DE LA FRANJA COSTERA SUR","value":29165368889, "aprobado": 29165368889 }, 

                      ]
                  },
                 
              ]
  }
      ,{
    "name":"Programa Sustantivo",
    "children": [
                { 
                    "name": "RED VIAL PAVIMENTADA INCREMENTADA" ,
                    "children": [
                        {"name":"MEJORAMIENTO DE 151 KM CPTAN. BADO-SANTA ROSA","value":  4530000000,"aprobado": 1303800000     },
                        {"name":"SP MEJ.TRAVESÍAS URB. CDAD. PARAGUARI-CARAPEGUA-QUIIDY-CAAP","value":  4000000000,"aprobado": 0    },
                        {"name":"SP FOCEM-REHAB. Y PAV. ASF. DEL TRAMO CONCEPCIÓN - VALLEMI","value":  12944593534,"aprobado": 6273471751     },
                        {"name":"MEJORAMIENTO PAV CORRED INTEG REHAB MANT RED VIAL(FASE II)","value":  90773600000,"aprobado":  90362900000    },
                        {"name":"MEJORAMIENTO DE CORREDORES DE INTEGRACIÓN Y RECONST. VIAL","value":  132090522909,"aprobado":   125088131174   },
                        {"name":"MEJORAMIENTO CORREDOR DE EXPORTACIÓN EN ITAPÚA Y ALTO PARANÁ","value":  244454356373,"aprobado": 244444756373     },
                        {"name":"MEJORAMIENTO TRAMO SAN JUAN NEPOMUCENO  - EMPALME RUTA VI","value":  112050000000,"aprobado":  112050000000    },
                        {"name":"MEJORAMIENTO TRAMO ALBERDI - PILAR","value":  173649202827,"aprobado": 170642002827     },
                        {"name":"HABILITACIÓN DE LA RED VIAL PAV. EN COR, PAR, CAG, GUA, CAZ","value":  146639665065,"aprobado": 146639665065     },
                        {"name":"HABILITACIÓN DE LA RED VIAL PAV. EN DPTO. DE SAN PEDRO","value":  18589567500,"aprobado": 58587167500     },
                        {"name":"HABILITACIÓN DE LA RED VIAL PAV. VILLETA - ALBERDI","value":  36748409409,"aprobado":  36748409409    },
                        {"name":"HABILITACIÓN DE LA RED PAV., DPTOS DE MISIONES E ITAPÚA","value":  91767420661,"aprobado": 91767420661     },
                        {"name":"HABILITACIÓN DE LA RED PAV. DPTOS. S. PEDRO, CAAG. Y CANIND.","value":  113842654646,"aprobado": 113842654646     },
                        {"name":"CONSERVACIÓN DE RUTAS PAVIMENTADAS POR ADMINISTRACIÓN","value":  148193823547,"aprobado": 137504323547     },
                        {"name":"MEJORAMIENTO DE LA FRANJA DE DOMINIO ZONA CHACO","value":  1500000000,"aprobado":  0    },
                        {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL ESTE","value":  6000000000,"aprobado":  0    },
                        {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL NORTE","value":  6291054415,"aprobado":  0    },
                        {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL SUR","value":  6000000000,"aprobado":    0  },
                        {"name":"CONSTRUCCIÓN Y MANTENIMIENTO RUTA Nº 3 TRAMO KM 21.8-170.3","value":  6500000000,"aprobado":   0   },
                        {"name":"CONSTRUCCIÓN Y MANTENIMIENTO RUTA Nº 4 TRAMO KM 225 - 358.4","value":  7300000000,"aprobado":  0    },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES-REGIÓN SURESTE-CPE","value":  216742860,"aprobado":    0  },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CENTRO-CPE","value":  1084000000,"aprobado":  80640000    },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CENTRO","value":  4600000000,"aprobado": 96000000     },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN SUR","value":  8084000000,"aprobado": 80640000     },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN ESTE","value":8100000000,"aprobado": 96000000    },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES -REGIÓN NORTE","value":2100000000,"aprobado": 96000000     },
                        {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CHACO","value":1000000000,"aprobado": 48000000    },
                        {"name":"CONSTRUCCIÓN PAVIMENTO TIPO ADOQUIN - REGIÓN SUR","value":5084000000,"aprobado": 80640000    },
                        {"name":"CONSTRUCCIÓN  PASOS A DESNIVEL - CENTRAL","value":2100000000,"aprobado": 96000000     },
                        {"name":"CONSTRUCCIÓN ALCANTARILLADO Y DESAGÜE PLUVIAL EN CENTRAL","value":100000000,"aprobado": 0    },
                        {"name":"CONSTRUCCIÓN PAV. S/ EMPEDRADOS EN REGIÓN NORTE","value":2000000000,"aprobado":  0   },
                        {"name":"CONSTRUCCIÓN DE PAV. S/ EMPEDRADOS EN REGIÓN ESTE","value":9800000000,"aprobado": 96000000    },
                        {"name":"CONSTRUCCIÓN ASFALTO S/ EMPEDRADOS - REGIÓN SUR","value":8100000000,"aprobado": 96000000    },
                        {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN NORESTE","value":22100000000,"aprobado": 29596000000     },
                        {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN ESTE","value":5100000000,"aprobado":  96000000   },
                        {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN CENTRO","value":47050000000,"aprobado": 33548000000   },
                        {"name":"CONSTRUCCIÓN Y PAV. ASFALTICA DE TR. CAMIN. EN REGIÓN CHACO","value":16050000000,"aprobado":    6048000000 },
                        {"name":"CONSTRUCCIÓN PAVIMENTACIÓNN NARANJAL SAN CRISTOBAL RUTA 6","value":9288635406,"aprobado": 9288635406    },
                        {"name":"AMPLIACIÓN Y DUPLICACIÓN DE LAS RUTAS 2 Y 7","value":269971257050,"aprobado":  268996257050   },
                        {"name":"MEJORAMIENTO TRAZADO DEL TRAMO LOMA PLATA - CARMELO PERALTA","value":42738787961,"aprobado":  42160010523   },
                        {"name":"CONSTRUCCIÓN DE PAV. S/ EMPEDRADOS EN REGIÓN CENTRO","value":8100000000,"aprobado": 96000000    },
                        {"name":"MEJORAMIENTO Y CONSERV. TRAMO CAAGUAZU - YHU. - EMP. RN 10","value":16000000000,"aprobado": 16000000000    },
                        {"name":"MEJORAMIENTO Y CONSERV. TRAMO ÑUMI - SAN JUAN N. - EMP. RN6","value":45870000000,"aprobado": 45870000000    },
                        {"name":"HABILITACIÓN Y MANT. DE VÍAS DE ACC. AL ÁREA METROP. DE ASU","value":15000000000,"aprobado": 15000000000     },
                        {"name":"HABILITACIÓN Y MANT. DE LA RUTA 1 TRAMO CARAPEGUA - SJB","value":102041547368,"aprobado": 62039147368    },
                        {"name":"HABILITACIÓN Y MANT. TRAMO POZO COLORADO - CONCEPCIÓN","value":245987625589,"aprobado": 245987625589    },
                        {"name":"HABILITACIÓN Y MANT. RUTA NACIONAL Nº 9 Y ACC. COL. MENONIT","value":475698000000,"aprobado": 475698000000     },
                        {"name":"MEJORAMIENTO RUTA N° 9 Y RAFAEL FRANCO","value":36143400000,"aprobado": 36143400000    },
                        {"name":"CONSTRUCCIÓN DE LA RUTA SAN PEDRO - BELEN - CONCEPCIÓN","value":219767507904,"aprobado": 219767507904    },
                    ]
                },
                {
                    "name":"TRANSITABILIDAD DE CAMINOS VECINALES Y RURALES MEJORADA",
                    "children": [
                        {"name":"SP PROG.NAC.CAM. RUR. 2° E.FASE II BID 2163/OC-2164/BL-OFID","value":  9982000000,"aprobado":  6925720000    },
                        {"name":"MEJORAMIENTO DE CAMINOS VECINALES Y PUENTES","value":23979386364,"aprobado":  22955948864   },
                        {"name":"MEJORAMIENTO CAMINOS VECINALES EN LA REGIÓN ORIENTAL","value":184375365867,"aprobado": 184371365867     },
                        {"name":"MEJORAMIENTO DE 160KM DE CAMINOS VECINALES EN LA R ORIENTAL","value":141735994721,"aprobado": 141724714721    },
                        {"name":"CONSERVACIÓN CAMINOS RURALES NO PAVIMENTADOS R. OCCIDENTAL","value":9572000000,"aprobado": 69120000     },
                        {"name":"CONSTRUCCIÓN CAMINOS RURALES NO PAVIMENTADOS R. ORIENTAL","value":15082173606,"aprobado":  15078886662    },
                        {"name":"CONSTRUCCIÓN PUENTES Y ALCANTARILLAS EN LA REGIÓN ORIENTAL","value":9672169851,"aprobado": 741283057    },
                        {"name":"CONSTRUCCIÓN PUENTES Y ALCANTARILLAS EN LA R.OCCIDENTAL","value":3546956346,"aprobado": 45078092    },
                        {"name":"CONSERVACIÓN CAMINOS RURALES NO PAVIMENT. R. ORIENTAL","value":15123912692,"aprobado":  15118956184    },
                        {"name":"LEVANTAMIENTO FACTIB. Y DISEÑO INGENIERÍA CAMINOS Y PUENTES","value":2536000000,"aprobado": 1284560000    },
                        {"name":"MEJORAMIENTO CAMINOS VECINALES Y PUENTES EN LA RO","value":291092465900,"aprobado": 291080945900    },
                        {"name":"MEJORAMIENTO DE LA GESTIÓN DE LOS DISTRITOS DEPARTAMENTALES","value":83290448400,"aprobado": 83115048400    },
                
                    ]
                },
                {
                    "name":"ACCESO A SISTEMAS DE AGUA POTABLE Y SANEAMIENTO",
                    "children": [
                        {"name":"SP PROYECTO ACUEDUCTO","value":21678006992,"aprobado": 21662585286    },
                        {"name":"SP PROG. SANEAM. Y AGUA POT. P/CHACO Y CIUD. INTERM. RE","value":126907611052,"aprobado": 120807792870    },
                        {"name":"MEJORAMIENTO DEL SERV. DE ALCANT. DE LA BAH. ASUNCIÓN","value":126205966418,"aprobado": 126205966418    },
                        {"name":"MEJORAMIENTO ALCANTARILLADO DE LA CIUDAD DE SAN LORENZO","value":82582290107,"aprobado": 82582290107     },
                    ]
                },
                
            ]
},{
  "name":"Partidas no asignables a Programas",
  "children": [
    {"name":"Transferencias Consolidables","value":53774694697,"aprobado": 53752688697    },
  ]
  
}]}},
{
  treeMapDataAprobado : {
    'name':"MINISTERIO DE OBRAS  PÚBLICAS Y COMUNICACIONES",
    'children':
    [{
    "name":"Programa Central",
    "children": [
                { 
                    "name": "Actividades Administrativas" ,
                    "children": [
                        {"name":"GESTION ADMINISTRATIVA FINANCIERA","aprobado":  371512289231, "value": 368446533000   },
                        
                    ]
                },
                {
                    "name":"Actividad Misional",
                    "children": [
                        {"name":"REGULACION DEL TRANSPORTE PÚBLICO DEL ÁREA METROPOLITANA","aprobado":34816987000, "value": 34816987000  },
                        {"name":"CONTROL DEL TRANSPORTE PÚBLICO DEL ÁREA METROPOLITANA","aprobado":  18799845549, "value": 18799845549   },
                        {"name":"FISCALIZACIONES DE LOS RECURSOS MINERALES Y ENERGETICOS","aprobado":  6837994933, "value": 6837994933   },
                        {"name":"CONTROL DE CUMPLIMIENTO DE LAS NORMAS DE TRANSITO","aprobado":  51422170537, "value": 51421603210   },
                        {"name":"FISCALIZACIONES DEL TRANSPORTE FLUVIO-MARITIMO","aprobado":  2667336228, "value": 2661840003   },
                        {"name":"REGULACION DEL TRANSPORTE FLUVIO-MARITIMO","aprobado":  2233095912, "value": 2226739524  },
                        {"name":"MANTENIMIENTO, CONSERVACION DE PATRIMONIO HISTORICO Y OTROS","aprobado":  3579000000, "value":  3567600000  },
                        {"name":"CONSERVACIÓN Y DESPEJE DE LA CONTAMINACIÓN DEL RIO PILCOMAYO","aprobado":  9842768360, "value": 5915284528   },
                        {"name":"MANTENIMIENTO DE MÁRGENES Y CAUCES DE ARROYOS","aprobado":  28807788180, "value":  15388619090 },
                        {"name":"RESTAURACIÓN TRAMO DE LA VIA FERREA - VILLA INGLESA","aprobado":  0, "value":  12000000000 }
                           
                    ]
                },
                {
                    "name":"Proyecto",
                    "children": [
                        {"name":"SP CONST. AVDA. COST. CENT. HIST. Y PARQUE BICENTENARIO","aprobado":14447423400, "value": 14425003400  },
                        {"name":"CONSTRUCCIÓN AV. COSTANERA NORTE, 2ª ETAPA","aprobado":  167130374717, "value": 164035339273  }, 
                        {"name":"DIAGNÓSTICO Y PROTOCOLO DE INTERVENCIÒN EDIF. HISTÒRICOS","aprobado":1700000000, "value": 1445000000 }, 
                        {"name":"RESTAURACIÓN DE EDIFICIOS PATRIMONIALES","aprobado":  16061828100, "value": 15911828100  }, 
                        {"name":"EQUIPAMIENTO PROVISIÓN Y COLOCACIÓN DE TEATROS","aprobado":3100000000, "value": 3100000000  }, 
                        {"name":"LEVANTAMIENTO DE DATOS Y ELABORACIÓN DE PROYECTOS EJECUTIVOS","aprobado":  1420000000, "value":710000000   }, 
                        {"name":"CONSTRUCCIÓN PENITENCIARÍA REGIONAL DE CIUDAD DEL ESTE","aprobado":  10000000000, "value": 0   }, 
                        {"name":"CONSTRUCCIÓN DEL CIRS DE EMBOSCADA","aprobado":  15000000000, "value": 15000000000   }, 
                        {"name":"SP REC. Z. PTO AS. CENT OFIC, CONT. P2419/OC-PR Y 2420/BL-PR","aprobado":  240981743440, "value": 229350964344   }, 
                        {"name":"ANÁLISIS ELAB.PLAN MAESTRO DE NAVEGACIÓN DEL RIO PY","aprobado":  12000000000, "value": 0  }, 
                        {"name":"CAPACITACIÓN Y MEJOR.FORM EJECUCIÓN DE PROYECTOS DE LA DIPE","aprobado":  10000000000, "value": 9250000000   }, 
                        {"name":"CONSTRUCCIÓN CONSTRUCCIÓN DE LA FRANJA COSTERA SUR","aprobado":29165368889, "value": 29165368889 }, 

                    ]
                },
               
            ]
}
    ,{
  "name":"Programa Sustantivo",
  "children": [
              { 
                  "name": "RED VIAL PAVIMENTADA INCREMENTADA" ,
                  "children": [
                      {"name":"MEJORAMIENTO DE 151 KM CPTAN. BADO-SANTA ROSA","aprobado":  4530000000,"value": 1303800000     },
                      {"name":"SP MEJ.TRAVESÍAS URB. CDAD. PARAGUARI-CARAPEGUA-QUIIDY-CAAP","aprobado":  4000000000,"value": 0    },
                      {"name":"SP FOCEM-REHAB. Y PAV. ASF. DEL TRAMO CONCEPCIÓN - VALLEMI","aprobado":  12944593534,"value": 6273471751     },
                      {"name":"MEJORAMIENTO PAV CORRED INTEG REHAB MANT RED VIAL(FASE II)","aprobado":  90773600000,"value":  90362900000    },
                      {"name":"MEJORAMIENTO DE CORREDORES DE INTEGRACIÓN Y RECONST. VIAL","aprobado":  132090522909,"value":   125088131174   },
                      {"name":"MEJORAMIENTO CORREDOR DE EXPORTACIÓN EN ITAPÚA Y ALTO PARANÁ","aprobado":  244454356373,"value": 244444756373     },
                      {"name":"MEJORAMIENTO TRAMO SAN JUAN NEPOMUCENO  - EMPALME RUTA VI","aprobado":  112050000000,"value":  112050000000    },
                      {"name":"MEJORAMIENTO TRAMO ALBERDI - PILAR","aprobado":  173649202827,"value": 170642002827     },
                      {"name":"HABILITACIÓN DE LA RED VIAL PAV. EN COR, PAR, CAG, GUA, CAZ","aprobado":  146639665065,"value": 146639665065     },
                      {"name":"HABILITACIÓN DE LA RED VIAL PAV. EN DPTO. DE SAN PEDRO","aprobado":  18589567500,"value": 58587167500     },
                      {"name":"HABILITACIÓN DE LA RED VIAL PAV. VILLETA - ALBERDI","aprobado":  36748409409,"value":  36748409409    },
                      {"name":"HABILITACIÓN DE LA RED PAV., DPTOS DE MISIONES E ITAPÚA","aprobado":  91767420661,"value": 91767420661     },
                      {"name":"HABILITACIÓN DE LA RED PAV. DPTOS. S. PEDRO, CAAG. Y CANIND.","aprobado":  113842654646,"value": 113842654646     },
                      {"name":"CONSERVACIÓN DE RUTAS PAVIMENTADAS POR ADMINISTRACIÓN","aprobado":  148193823547,"value": 137504323547     },
                      {"name":"MEJORAMIENTO DE LA FRANJA DE DOMINIO ZONA CHACO","aprobado":  1500000000,"value":  0    },
                      {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL ESTE","aprobado":  6000000000,"value":  0    },
                      {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL NORTE","aprobado":  6291054415,"value":  0    },
                      {"name":"MEJORAMIENTO DE LA SEÑALIZACIÓN HORIZONTAL Y VERTICAL SUR","aprobado":  6000000000,"value":    0  },
                      {"name":"CONSTRUCCIÓN Y MANTENIMIENTO RUTA Nº 3 TRAMO KM 21.8-170.3","aprobado":  6500000000,"value":   0   },
                      {"name":"CONSTRUCCIÓN Y MANTENIMIENTO RUTA Nº 4 TRAMO KM 225 - 358.4","aprobado":  7300000000,"value":  0    },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES-REGIÓN SURESTE-CPE","aprobado":  216742860,"value":    0  },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CENTRO-CPE","aprobado":  1084000000,"value":  80640000    },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CENTRO","aprobado":  4600000000,"value": 96000000     },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN SUR","aprobado":  8084000000,"value": 80640000     },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN ESTE","aprobado":8100000000,"value": 96000000    },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES -REGIÓN NORTE","aprobado":2100000000,"value": 96000000     },
                      {"name":"CONSTRUCCIÓN PAV. TIPO EMPED. Y PUENTES - REGIÓN CHACO","aprobado":1000000000,"value": 48000000    },
                      {"name":"CONSTRUCCIÓN PAVIMENTO TIPO ADOQUIN - REGIÓN SUR","aprobado":5084000000,"value": 80640000    },
                      {"name":"CONSTRUCCIÓN  PASOS A DESNIVEL - CENTRAL","aprobado":2100000000,"value": 96000000     },
                      {"name":"CONSTRUCCIÓN ALCANTARILLADO Y DESAGÜE PLUVIAL EN CENTRAL","aprobado":100000000,"value": 0    },
                      {"name":"CONSTRUCCIÓN PAV. S/ EMPEDRADOS EN REGIÓN NORTE","aprobado":2000000000,"value":  0   },
                      {"name":"CONSTRUCCIÓN DE PAV. S/ EMPEDRADOS EN REGIÓN ESTE","aprobado":9800000000,"value": 96000000    },
                      {"name":"CONSTRUCCIÓN ASFALTO S/ EMPEDRADOS - REGIÓN SUR","aprobado":8100000000,"value": 96000000    },
                      {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN NORESTE","aprobado":22100000000,"value": 29596000000     },
                      {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN ESTE","aprobado":5100000000,"value":  96000000   },
                      {"name":"CONSTRUCCIÓN Y PAV. ASF. DE TR. CAMINEROS EN REGIÓN CENTRO","aprobado":47050000000,"value": 33548000000   },
                      {"name":"CONSTRUCCIÓN Y PAV. ASFALTICA DE TR. CAMIN. EN REGIÓN CHACO","aprobado":16050000000,"value":    6048000000 },
                      {"name":"CONSTRUCCIÓN PAVIMENTACIÓNN NARANJAL SAN CRISTOBAL RUTA 6","aprobado":9288635406,"value": 9288635406    },
                      {"name":"AMPLIACIÓN Y DUPLICACIÓN DE LAS RUTAS 2 Y 7","aprobado":269971257050,"value":  268996257050   },
                      {"name":"MEJORAMIENTO TRAZADO DEL TRAMO LOMA PLATA - CARMELO PERALTA","aprobado":42738787961,"value":  42160010523   },
                      {"name":"CONSTRUCCIÓN DE PAV. S/ EMPEDRADOS EN REGIÓN CENTRO","aprobado":8100000000,"value": 96000000    },
                      {"name":"MEJORAMIENTO Y CONSERV. TRAMO CAAGUAZU - YHU. - EMP. RN 10","aprobado":16000000000,"value": 16000000000    },
                      {"name":"MEJORAMIENTO Y CONSERV. TRAMO ÑUMI - SAN JUAN N. - EMP. RN6","aprobado":45870000000,"value": 45870000000    },
                      {"name":"HABILITACIÓN Y MANT. DE VÍAS DE ACC. AL ÁREA METROP. DE ASU","aprobado":15000000000,"value": 15000000000     },
                      {"name":"HABILITACIÓN Y MANT. DE LA RUTA 1 TRAMO CARAPEGUA - SJB","aprobado":102041547368,"value": 62039147368    },
                      {"name":"HABILITACIÓN Y MANT. TRAMO POZO COLORADO - CONCEPCIÓN","aprobado":245987625589,"value": 245987625589    },
                      {"name":"HABILITACIÓN Y MANT. RUTA NACIONAL Nº 9 Y ACC. COL. MENONIT","aprobado":475698000000,"value": 475698000000     },
                      {"name":"MEJORAMIENTO RUTA N° 9 Y RAFAEL FRANCO","aprobado":36143400000,"value": 36143400000    },
                      {"name":"CONSTRUCCIÓN DE LA RUTA SAN PEDRO - BELEN - CONCEPCIÓN","aprobado":219767507904,"value": 219767507904    },
                  ]
              },
              {
                  "name":"TRANSITABILIDAD DE CAMINOS VECINALES Y RURALES MEJORADA",
                  "children": [
                      {"name":"SP PROG.NAC.CAM. RUR. 2° E.FASE II BID 2163/OC-2164/BL-OFID","aprobado":  9982000000,"value":  6925720000    },
                      {"name":"MEJORAMIENTO DE CAMINOS VECINALES Y PUENTES","aprobado":23979386364,"value":  22955948864   },
                      {"name":"MEJORAMIENTO CAMINOS VECINALES EN LA REGIÓN ORIENTAL","aprobado":184375365867,"value": 184371365867     },
                      {"name":"MEJORAMIENTO DE 160KM DE CAMINOS VECINALES EN LA R ORIENTAL","aprobado":141735994721,"value": 141724714721    },
                      {"name":"CONSERVACIÓN CAMINOS RURALES NO PAVIMENTADOS R. OCCIDENTAL","aprobado":9572000000,"value": 69120000     },
                      {"name":"CONSTRUCCIÓN CAMINOS RURALES NO PAVIMENTADOS R. ORIENTAL","aprobado":15082173606,"value":  15078886662    },
                      {"name":"CONSTRUCCIÓN PUENTES Y ALCANTARILLAS EN LA REGIÓN ORIENTAL","aprobado":9672169851,"value": 741283057    },
                      {"name":"CONSTRUCCIÓN PUENTES Y ALCANTARILLAS EN LA R.OCCIDENTAL","aprobado":3546956346,"value": 45078092    },
                      {"name":"CONSERVACIÓN CAMINOS RURALES NO PAVIMENT. R. ORIENTAL","aprobado":15123912692,"value":  15118956184    },
                      {"name":"LEVANTAMIENTO FACTIB. Y DISEÑO INGENIERÍA CAMINOS Y PUENTES","aprobado":2536000000,"value": 1284560000    },
                      {"name":"MEJORAMIENTO CAMINOS VECINALES Y PUENTES EN LA RO","aprobado":291092465900,"value": 291080945900    },
                      {"name":"MEJORAMIENTO DE LA GESTIÓN DE LOS DISTRITOS DEPARTAMENTALES","aprobado":83290448400,"value": 83115048400    },
              
                  ]
              },
              {
                  "name":"ACCESO A SISTEMAS DE AGUA POTABLE Y SANEAMIENTO",
                  "children": [
                      {"name":"SP PROYECTO ACUEDUCTO","aprobado":21678006992,"value": 21662585286    },
                      {"name":"SP PROG. SANEAM. Y AGUA POT. P/CHACO Y CIUD. INTERM. RE","aprobado":126907611052,"value": 120807792870    },
                      {"name":"MEJORAMIENTO DEL SERV. DE ALCANT. DE LA BAH. ASUNCIÓN","aprobado":126205966418,"value": 126205966418    },
                      {"name":"MEJORAMIENTO ALCANTARILLADO DE LA CIUDAD DE SAN LORENZO","aprobado":82582290107,"value": 82582290107     },
                  ]
              },
              
          ]
},{
"name":"Partidas no asignables a Programas",
"children": [
  {"name":"Transferencias Consolidables","aprobado":53774694697,"value": 53752688697    },
]

}]}}
,{resumen:[
      {
        nombre:"ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN",
        porcentaje:"41.38"
      }, {
        nombre:"EDUCACIÓN MEDIA INTEGRADA",
        porcentaje:" 0.010"
      },
      {
        nombre:"CALIDAD DEL APRENDIZAJE",
        porcentaje:"14.28"
      },
      {
        nombre:"CULMINACIÓN OPORTUNA DE ESTUDIOS",
        porcentaje:"44"
      },
]}
  ],
},
{
  salud:[{
    data:{
      labels: ["Programa Central","Programa Sustantivo", "Partidas no asignables a Programas"],
      datasets: [
        {
          data: [   29.84, 70.08 , 0.08],
          backgroundColor: ["orange", "#FAFF0E",'blue'],
          hoverBackgroundColor: ["orange", "#FAFF0E",'blue']
        }
    ]
  }},
  {items: [
    "Acceso a unidades de salud familiar integrales y de calidad en comunidades",
    "Servicios hospitalarios para la reducción de muertes causadas por enfermedades",
    "Servicios de apoyo y diagnóstico para mejora en la atención  ",
    "Aumento de la cobertura en la atención y medicamentos a enfermedades especiales (físicas y mentales)",
    "Sensibilización, asistencia y vigilancia en materia de alimentación y nutrición",
    "Bienestar social y atención a adultos mayores y otras personas en situaciones de riesgo ",
    "Acceso a los servicios de agua potable y saneamiento ambiental en comunidades rurales e indígenas"
    
  ]},{
  datos:{
    nombre:"MINISTERIO DE SALUD PÚBLICA Y BIENESTAR SOCIAL",
    slug:'/salud',
    programas:[{
      nombre:"Programa Central",
      monto: "G. 1.737.697.717.915  "
    },{
      nombre:"Misional",
      monto:"G. 208.058.116.414",
      aprobado: 217512572353
    },{
      nombre:"Administrativo",
      monto:"G.  1.236.811.713.318",
      aprobado: 1118971189504
    },
    {
      nombre:"Proyecto",
      monto:"G. 292.827.888.183 ",
      aprobado: 292820792904
    },{
      nombre:"Programa sustantivo",
      monto:"G. 4.081.022.674.528",
     
    },
    {
      nombre:"Proyecto.",
      monto:"G. 120.694.316.564",
      aprobado:120663727997
    },{
      nombre:"Actividad",
      monto:"G. 3.960.328.357.964",
      aprobado:4225190448042
    },{
      nombre:"Partidas no asignables a Programas",
      monto:"G. 4.565.004.915",
      aprobado:4523060115
    }
  ],
  presupuestoTotal: "G. 5.823.285.397.358",
  
  },
 
    
  },{
    treeMapData : {
      'name':"MINISTERIO DE SALUD PÚBLICA Y BIENESTAR SOCIAL",
      'children':
      [{
      "name":"Programa Central",
      "children": [
                  { 
                      "name": "Actividades Administrativas" ,
                      "children": [
                          {"name":"Gestión administrativa p/el funcionamiento institucional","value":  1188371059555,"aprobado": 1070530535741 
                        },
                          {"name":"Recursos financieros transferidos a consejos de salud ","value":  48440653763 ,"aprobado":  48440653763 
                        }, 
                      ]
                  },
                  {
                      "name":"Actividad Misional",
                      "children": [
                          {"name":"ACCIONES PARA EL APOYO A LOS SERV. DE SALUD","value":  28693581338 ,"aprobado":  28693581338 
                        },
                          {"name":"CONTROL DE LA ZOONOSIS","value":  5807585146, "aprobado":  5650337146 
                        },
                          {"name":"ENFERMEDADES. TRANSMITIDAS POR VECTORES REDUCIDAS Y CONTROLADAS","value":  116013836082,"aprobado":  122829684082 
                        },
                          {"name":"CURSOS Y CAPACITACIONES EN EL ÁREA DE SALUD","value":  882673445,"aprobado":  882673445 
                        },
                          {"name":"ACCIONES PARA LA ATENCIÓN INTEGRAL DEL VIH-SIDA","value":  2362552658,"aprobado":  2355905872 
                        },
                          {"name":"VIGILANCIA DE SALUD Y RIESGOS ASOCIADOS A SUS DETERMINANTES","value":  8978755067,"aprobado": 10133685730 
                        },
                          {"name":"VIGILANCIA DE ENFERMEDADES NO TRANSMISIBLES","value":  590864166,"aprobado":  588083164 
                        },
                          {"name":"ATENCIÓN INTEGRAL A PACIENTES CON ENFERM. DE LA DIABETES","value":  868798357,"aprobado":   862318846 
                        },
                          {"name":"GENERACIÓN DE INFORMACIÓN DE CALID S/ LA SITUAC DE SALUD D TERRI","value":  2925992407,"aprobado":  2902164723 
                        },
                          {"name":"INSPECCIONES Y HABILITACIONES A ESTABLECIMIENTOS SANITARIOS","value":  20403939270,"aprobado": 20403939270 
                        },
                          {"name":"REGISTROS NUEVOS Y RENOVACIÓN DE PRODUCTOS SANITARIOS","value":  11220840095,"aprobado":   11220840095 
                        },
                          {"name":"CATEG. Y ACRED. ENTID. PRESTAD. DE SERV. DE SALUD NIVEL NAC","value":  7058258256,"aprobado":  8748273072 
                        },
                          {"name":"REGULAR Y FISCALIZAR LA UTILIZACIÓN DE SANGRE HUMANA Y DERIV","value":  2250440127,"aprobado":  2241085570 
                        },
                              
                      ]
                  },
                  {
                      "name":"Proyecto",
                      "children": [
                          {"name":"MEJORAMIENTO FORT. Y APOYO INTEGRAL A LOS SERV DE SALUD PUB ","value":    64038335529,"aprobado":  64038335529 
                        },
                          {"name":"CONSTRUCCIÓN GRAN HOSPITAL GENERAL DE BARRIO OBRERO ","value":    50000000000,"aprobado":  50000000000 
                        },
                          {"name":"CONSTRUCCIÓN DEL HOSPITAL NACIONAL  DE CORONEL OVIEDO ","value":    50000000000,"aprobado":  50000000000 
                        },
                          {"name":"SP PROGRAMA DE DESARROLLO INFANTIL TEMPRANO (DIT)","value":  120322934005,"aprobado": 120317800894 
                        },
                          {"name":"INVESTIGACIÓN ,EDUC. Y BIOTECNOLOGÍA APLICADAS A LA SALUD","value":8466618649,"aprobado": 8464656481 
                        },

                      ]
                  },
                 
              ]
  }
      ,{
    "name":"Programa Sustantivo",
    "children": [
                { 
                    "name": "ACCESO A LA ATENCIÓN PRIMARIA DE LA SALUD" ,
                    "children": [
                        {"name":"SERVICIOS DE ATENCIÓN PRIMARIA DE LA SALUD","value":309911543761,"aprobado":  309485789169 
                      }
                    ]
                },{
                  "name":"SERVICIOS HOSPITALARIOS P/LA REDUCCIÓN DE LA MORBIMORTALIDAD",
                  "children":[
                    {"name":"SERVICIOS DE ATENC. INTEGRAL. A LA POBLACIÓN DE CONCEPCIÓN","value":  55801106377,"aprobado":  55778403909 
                  },
                    {"name":"SERV. DE ATEN. INTEGRAL A LA POBLACIÓN DE SAN PEDRO","value":76862309275,"aprobado": 76765638625 
                  },
                    {"name":"SERV. DE ATEN. INTEG. A LA  POBLACIÓN DE CORDILLERA","value":71820918883,"aprobado":  71795491444 
                  },
                    {"name":"SERV. DE ATEN.INT. A POBLACIÓN DE GUAIRÁ","value":64350167565,"aprobado":  64289689672 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CAAGUAZÚ","value":91121445637,"aprobado":  91103961130 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CAAZAPÁ","value":44879893001,"aprobado": 44939872701 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ITAPÚA","value":83796345505,"aprobado":  83728622465 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE MISIONES","value":60162459099,"aprobado":  60102100470 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE PARAGUARÍ","value":80022221482,"aprobado":  79870347627 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ALTO PARANÁ","value":99364223514,"aprobado":  104288829198 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CENTRAL","value":93020175862,"aprobado":  92955101349 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ÑEEMBUCU","value":39663885233,"aprobado":  39556177283 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE AMAMBAY","value":36170787035,"aprobado":  36042154341 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CANINDEYÚ","value":41038223737,"aprobado":  40938505831 
                  },
                   
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE PDTE HAYES","value":31817105807,"aprobado":  31745742023 
                  },
                    {"name":"SERV. DE ATEN. INT.  A  POBLACIÓN DE BOQUERÓN","value":18572802435,"aprobado":  18495089220 
                  },
                    {"name":"SERV. DE ATEN. INT. A  POBLACIÓN DE ALTO PARAGUAY","value":15751440557,"aprobado":  16041911067 
                  },
                    {"name":"SERV. DE ATEN. INT. A  POBLACIÓN DE LA CAPITAL","value":62949382930,"aprobado":  62330942339 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE VILLETA","value":9858808771,"aprobado":  9851405852 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. BARRIO OBRERO","value":54023415876,"aprobado":  53991903140 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. DE ITÁ","value":14410845974,"aprobado":  14398659767 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.D. ITAUGUA","value":7718848600,"aprobado":  7714488823 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. DE LUQUE","value":59581707293,"aprobado":  59544474321 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. DE ÑEMBY","value":28017606425,"aprobado":  28000225283 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. SAN LORENZO","value":46198527480,"aprobado": 46175987512 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. DE VILLA ELISA","value":19650516516,"aprobado": 19632386350 
                  },
                    {"name":"SERV. DE ATENCIÓN INTEGRAL A LA POB. EN EL H. PEDIÁTRICO","value":41073409576,"aprobado":  49816655961 
                  },
                    {"name":"SERV. DE ATEN.INT. A POBLAC. EN EL HOSP. NACIONAL","value":228983333498,"aprobado":  244396149667 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. STA. ROSA DEL A.","value":27993536329,"aprobado": 27993189714 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. LAMBARÉ","value":34456407466,"aprobado": 34437057714 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.FDO DE LA MORA","value":26054885889,"aprobado":  26036748743 
                  },
                    {"name":"SERV. DE ATENC. INT. A POBLACIÓN EN EL H. LIMPIO","value":28906653957,"aprobado":  28894381511 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. LOMA PYTA","value":24330118123,"aprobado":  24309182820 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. CAPIATÁ","value":36051895388,"aprobado":  36027366170 
                  },
                    {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.M.R.A.","value":28053613342,"aprobado":  28012329651 
                  },
                    {"name":"SERV. DE ATEN. EN EL INST. NAC. DE ABLACIÓN Y TRASP.","value":4532859515,"aprobado":  114301285406 
                  },
                    {"name":"ATEN  INT  A POBLACIÓN H. INDÍGENA SAN ROQUE G. SC.","value":7308929146,"aprobado":  9308929146 
                  },
                    {"name":"ATEN. DE AFECCIONES CARDIOVAS. H. SAN JORGE","value":25417607276,"aprobado": 25406475762 },
                    {"name":"ATENCIÓN A POBLAC. PROG. NAC. PREV. CARDIOVASCULAR","value":2026338844,"aprobado":    2021804617 

                  },
                    {"name":"ATENCIÓN INT. EN SALUD RESPIRATORIA - INERAM","value":43151723264,"aprobado":  43118512084 
                  },
                    {"name":"ATENCIÓN DE POBLAC. CON ENFERM. INFECCIOSAS IMT","value":32544128372,"aprobado":  32496323801 
                  },
                    {"name":"ATENCIÓN A LA POBLACIÓN CON ENFERMEDADES ONCOLÓGICAS-INCAN","value":351742128125,"aprobado":  354742128125 
                  },
                    {"name":"ATENC. DE POBLAC. C/ ENFERM. ONCOLOG. DE CUELLO UTERINO","value":1219697453,"aprobado":  1214600409 
                  },
                    {"name":"ATENC. INT. DEL QUEMADO CENQUER","value":26302722636,"aprobado":  26117996437 
                  },
                    {"name":"ATEN. INT. EN EL CENT. NAC. DE CONTROL DE ADICCIONES","value":9695223179,"aprobado": 9690481291 
                  },
                    {"name":"ATENCION A PACIENTES RENALES INST. NAC. DE NEFROLOGÍA","value":11677155387,"aprobado": 11671364468 
                  },
                    {"name":"ATENCIÓN A LAS URGENCIAS MÉDICO, QUIRURGICAS - H . TRAUMA","value":143931084864,"aprobado": 158690922517 
                  },
                    {"name":"SERVICIO DE PROVISIÓN DE LECHE MATERNA - BANCO DE LECH","value":87372284,"aprobado": 87372284 
                  },
                    {"name":"ATENCIÓN A LA POBLACIÓN CON ENFERMEDAD MENTAL - H. PSIQUIÁTR","value":32934753373,"aprobado": 32847557297},
                    {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN MAT. INF. SAN PABLO","value":58552851083,"aprobado": 58524722636},
                    {"name":"ATENCIÓN INTEGRAL A POBLACIÓN MAT. INF. CRUZ ROJA PYA.","value":23361284705,"aprobado":23354380330},
                    {"name":"ATENCIÓN INTEGRAL A POBLACIÓN MAT. INF. STMA.TRINIDAD","value":30623703444,"aprobado": 30605269443},
                    {"name":"CAPACITACIONES, FORMACIÓN E INVESTIGACIÓN EN EL IMT","value":235504370,"aprobado": 235504370},
                    {"name":"CAPACITACIONES, FORMACIÓN E INVESTIGACIÓN EN EL H. NACIONAL","value":1295704508,"aprobado":1246588131},
                    {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL H. PEDIÁTRICO","value":23900740845,"aprobado": 23900740845},
                    {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL INERAN","value":180090481,"aprobado": 180090481},
                    {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL CENQUER","value":745348726,"aprobado":740348726},
                  ]
                },{ 
                  "name": "SERVICIOS DE APOYO Y DIAGNÓSTICO P/ LA MEJORA EN LA ATENCIÓN" ,
                  "children": [
                      {"name":"OBTENCIÓN, PRODUCCIÓN Y SUMINISTROS DE SANGRE","value":  6500188428,"aprobado": 6493244129 },
                      {"name":"HABILITACIÓN Y CONTROL DE LABORATORIOS DE ANÁLISIS CLÍNICOS","value":16344856465,"aprobado":16344675375},
                      {"name":"SERVICIOS LABORATORIALES","value":    7025618152 ,"aprobado": 7011495785},
                      {"name":"GESTIONES INTEGRADAS PARA LA PROVISIÓN DE BIOLÓGICOS","value":190399109118,"aprobado": 190365890969},
                      {"name":"INTERVENCIONES EN CASOS EMERG. EXTRAHOSPITALARIAS","value":59316980535,"aprobado": 59252174844},
                      {"name":"PROV. DE MEDICAMENTOS, INSUMOS, INST.Y EQUIPOS","value":492826057540,"aprobado": 632096120173},
                  ]
              },
              { 
                "name": "AUMENTO DE LA COBERTURA EN LA ATENC. A ENFERM. ESPECIALES" ,
                "children": [
                    {"name":"PROMOCIÓN Y TRATAMIENTO DE FIBRÓSIS QUÍSTICA Y RETARDO MENTA","value":584427801,"aprobado": 582836227},
                    {"name":"MEDICAMENTOS PARA ENFERMEDADES LISOSOMALES","value":6440000000,"aprobado":6440000000},
                    {"name":"ATENCIÓN INTEGRAL A LA POB. CON ENFERMEDADES OCULARES","value":3662259656,"aprobado":3652440717},
                    {"name":"ATENCIÓN INTEGRAL DE POBLACIÓN CON ENFERMEDADES BUCALES","value":4397466098,"aprobado":4371133696},
                    {"name":"ENTREGA DE INSUMOS PARA PERSONAS CON OSTOMÍA","value":2097600000,"aprobado":2097600000},
                    {"name":"ASISTENCIA A LA POBLACIÓN (ANATOMÍA Y SALUD MENTAL)","value":484826985,"aprobado": 480808374},
                    {"name":"CAPACITACIONES DE RR.HH. DEL MSPYBS.","value":5960514798,"aprobado": 5913659290},
                    {"name":"ENTREGA DE MEDICAMENTOS, KIT DE PARTO Y ANTICONCEPTIVOS","value":16210434870,"aprobado":16200496033},
                ]
            },
            { 
              "name": "SEGURIDAD ALIMENTARIA NUTRICIONAL HUMANA MEJORADA" ,
              "children": [
                  {"name":"VIGILANCIA ALIMENTARIA NUTRICIONAL","value":22085169959,"aprobado": 22079785799},
                  {"name":"SENSIBILIZACIÓN EN TEMAS DE ALIMENTACIÓN Y NUTRICIÓN","value":3913924211,"aprobado": 3913924211},
                  {"name":"ASISTENCIA ALIMENTARIA NUTRICIONAL","value":102858928891,"aprobado":102846238891},

              ]
          },
          { 
            "name": "MEJORA EN EL BIENESTAR SOCIAL P/ PER. EN SITUAC. DE RIESGO" ,
            "children": [
                {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN ADULTA MAYOR","value":1913599016,"aprobado":1911871348 },
                {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN EN SITUACIÓN DE RIESGO","value":25494285385,"aprobado": 25480683755},
                {"name":"REGISTRO Y ACREDITACIÓN DE ENTIDADES SIN FINES DE LUCRO","value":960742374,"aprobado": 959215707},
            ]
        },
        { 
          "name": "ACCESO A LOS SERV. DE AGUA POTABLE Y SANEAMIENTO AMBIENTAL" ,
          "children": [
              {"name":"GESTIÓN ADMINISTRATIVA","value":46458721500,"aprobado": 46238172441 },
              {"name":"CONSTRUCCIÓN SEMB. OPORT.- 480 SISTEMA DE AGUA Y SANEAM.","value":3528327466,"aprobado": 3504807149 },
              {"name":"SP ABAST.DE AGUA. POT Y SAN BAS PEQ COM RURAL E INDI(FOCEM)","value":12359746995,"aprobado": 12352678725 },
      
              {"name":"CONSTRUCCIÓN SIST AGUAPOT Y SAN PEQ CIUD COM RURAL E INDIG.","value":104806242103,"aprobado": 104806242103 },
              {"name":"ACCIONES DE VIGILANCIA DE CALIDAD AMBIENTAL","value":20485126104,"aprobado": 20467618810 },
              
          ]
      }
                
               
                
            ]
},
{
  "name":"Partidas no asignables a Programas",
  "value":"4565004915"  ,"aprobado": 4523060115 
}]}},{
  treeMapDataAprobado : {
    'name':"MINISTERIO DE SALUD PÚBLICA Y BIENESTAR SOCIAL",
    'children':
    [{
    "name":"Programa Central",
    "children": [
                { 
                    "name": "Actividades Administrativas" ,
                    "children": [
                        {"name":"Gestión administrativa p/el funcionamiento institucional","aprobado":  1188371059555,"value": 1070530535741 
                      },
                        {"name":"Recursos financieros transferidos a consejos de salud ","aprobado":  48440653763 ,"value":  48440653763 
                      }, 
                    ]
                },
                {
                    "name":"Actividad Misional",
                    "children": [
                        {"name":"ACCIONES PARA EL APOYO A LOS SERV. DE SALUD","aprobado":  28693581338 ,"value":  28693581338 
                      },
                        {"name":"CONTROL DE LA ZOONOSIS","aprobado":  5807585146, "value":  5650337146 
                      },
                        {"name":"ENFERMEDADES. TRANSMITIDAS POR VECTORES REDUCIDAS Y CONTROLADAS","aprobado":  116013836082,"value":  122829684082 
                      },
                        {"name":"CURSOS Y CAPACITACIONES EN EL ÁREA DE SALUD","aprobado":  882673445,"value":  882673445 
                      },
                        {"name":"ACCIONES PARA LA ATENCIÓN INTEGRAL DEL VIH-SIDA","aprobado":  2362552658,"value":  2355905872 
                      },
                        {"name":"VIGILANCIA DE SALUD Y RIESGOS ASOCIADOS A SUS DETERMINANTES","aprobado":  8978755067,"value": 10133685730 
                      },
                        {"name":"VIGILANCIA DE ENFERMEDADES NO TRANSMISIBLES","aprobado":  590864166,"value":  588083164 
                      },
                        {"name":"ATENCIÓN INTEGRAL A PACIENTES CON ENFERM. DE LA DIABETES","aprobado":  868798357,"value":   862318846 
                      },
                        {"name":"GENERACIÓN DE INFORMACIÓN DE CALID S/ LA SITUAC DE SALUD D TERRI","aprobado":  2925992407,"value":  2902164723 
                      },
                        {"name":"INSPECCIONES Y HABILITACIONES A ESTABLECIMIENTOS SANITARIOS","aprobado":  20403939270,"value": 20403939270 
                      },
                        {"name":"REGISTROS NUEVOS Y RENOVACIÓN DE PRODUCTOS SANITARIOS","aprobado":  11220840095,"value":   11220840095 
                      },
                        {"name":"CATEG. Y ACRED. ENTID. PRESTAD. DE SERV. DE SALUD NIVEL NAC","aprobado":  7058258256,"value":  8748273072 
                      },
                        {"name":"REGULAR Y FISCALIZAR LA UTILIZACIÓN DE SANGRE HUMANA Y DERIV","aprobado":  2250440127,"value":  2241085570 
                      },
                            
                    ]
                },
                {
                    "name":"Proyecto",
                    "children": [
                        {"name":"MEJORAMIENTO FORT. Y APOYO INTEGRAL A LOS SERV DE SALUD PUB ","aprobado":    64038335529,"value":  64038335529 
                      },
                        {"name":"CONSTRUCCIÓN GRAN HOSPITAL GENERAL DE BARRIO OBRERO ","aprobado":    50000000000,"value":  50000000000 
                      },
                        {"name":"CONSTRUCCIÓN DEL HOSPITAL NACIONAL  DE CORONEL OVIEDO ","aprobado":    50000000000,"value":  50000000000 
                      },
                        {"name":"SP PROGRAMA DE DESARROLLO INFANTIL TEMPRANO (DIT)","aprobado":  120322934005,"value": 120317800894 
                      },
                        {"name":"INVESTIGACIÓN ,EDUC. Y BIOTECNOLOGÍA APLICADAS A LA SALUD","aprobado":8466618649,"value": 8464656481 
                      },

                    ]
                },
               
            ]
}
    ,{
  "name":"Programa Sustantivo",
  "children": [
              { 
                  "name": "ACCESO A LA ATENCIÓN PRIMARIA DE LA SALUD" ,
                  "children": [
                      {"name":"SERVICIOS DE ATENCIÓN PRIMARIA DE LA SALUD","aprobado":309911543761,"value":  309485789169 
                    }
                  ]
              },{
                "name":"SERVICIOS HOSPITALARIOS P/LA REDUCCIÓN DE LA MORBIMORTALIDAD",
                "children":[
                  {"name":"SERVICIOS DE ATENC. INTEGRAL. A LA POBLACIÓN DE CONCEPCIÓN","aprobado":  55801106377,"value":  55778403909 
                },
                  {"name":"SERV. DE ATEN. INTEGRAL A LA POBLACIÓN DE SAN PEDRO","aprobado":76862309275,"value": 76765638625 
                },
                  {"name":"SERV. DE ATEN. INTEG. A LA  POBLACIÓN DE CORDILLERA","aprobado":71820918883,"value":  71795491444 
                },
                  {"name":"SERV. DE ATEN.INT. A POBLACIÓN DE GUAIRÁ","aprobado":64350167565,"value":  64289689672 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CAAGUAZÚ","aprobado":91121445637,"value":  91103961130 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CAAZAPÁ","aprobado":44879893001,"value": 44939872701 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ITAPÚA","aprobado":83796345505,"value":  83728622465 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE MISIONES","aprobado":60162459099,"value":  60102100470 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE PARAGUARÍ","aprobado":80022221482,"value":  79870347627 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ALTO PARANÁ","aprobado":99364223514,"value":  104288829198 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CENTRAL","aprobado":93020175862,"value":  92955101349 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE ÑEEMBUCU","aprobado":39663885233,"value":  39556177283 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE AMAMBAY","aprobado":36170787035,"value":  36042154341 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE CANINDEYÚ","aprobado":41038223737,"value":  40938505831 
                },
                 
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE PDTE HAYES","aprobado":31817105807,"value":  31745742023 
                },
                  {"name":"SERV. DE ATEN. INT.  A  POBLACIÓN DE BOQUERÓN","aprobado":18572802435,"value":  18495089220 
                },
                  {"name":"SERV. DE ATEN. INT. A  POBLACIÓN DE ALTO PARAGUAY","aprobado":15751440557,"value":  16041911067 
                },
                  {"name":"SERV. DE ATEN. INT. A  POBLACIÓN DE LA CAPITAL","aprobado":62949382930,"value":  62330942339 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN DE VILLETA","aprobado":9858808771,"value":  9851405852 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. BARRIO OBRERO","aprobado":54023415876,"value":  53991903140 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. DE ITÁ","aprobado":14410845974,"value":  14398659767 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.D. ITAUGUA","aprobado":7718848600,"value":  7714488823 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. DE LUQUE","aprobado":59581707293,"value":  59544474321 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN H. DE ÑEMBY","aprobado":28017606425,"value":  28000225283 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. SAN LORENZO","aprobado":46198527480,"value": 46175987512 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. DE VILLA ELISA","aprobado":19650516516,"value": 19632386350 
                },
                  {"name":"SERV. DE ATENCIÓN INTEGRAL A LA POB. EN EL H. PEDIÁTRICO","aprobado":41073409576,"value":  49816655961 
                },
                  {"name":"SERV. DE ATEN.INT. A POBLAC. EN EL HOSP. NACIONAL","aprobado":228983333498,"value":  244396149667 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. STA. ROSA DEL A.","aprobado":27993536329,"value": 27993189714 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. LAMBARÉ","aprobado":34456407466,"value": 34437057714 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.FDO DE LA MORA","aprobado":26054885889,"value":  26036748743 
                },
                  {"name":"SERV. DE ATENC. INT. A POBLACIÓN EN EL H. LIMPIO","aprobado":28906653957,"value":  28894381511 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. LOMA PYTA","aprobado":24330118123,"value":  24309182820 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H. CAPIATÁ","aprobado":36051895388,"value":  36027366170 
                },
                  {"name":"SERV. DE ATEN. INT. A POBLACIÓN EN EL H.M.R.A.","aprobado":28053613342,"value":  28012329651 
                },
                  {"name":"SERV. DE ATEN. EN EL INST. NAC. DE ABLACIÓN Y TRASP.","aprobado":4532859515,"value":  114301285406 
                },
                  {"name":"ATEN. INT. A POBLACIÓN H. INDÍGENA SAN ROQUE G. SC.","aprobado":7308929146,"value":  9308929146 
                },
                  {"name":"ATEN. DE AFECCIONES CARDIOVAS. H. SAN JORGE","aprobado":25417607276,"value": 25406475762 },
                  {"name":"ATENCIÓN A POBLAC. PROG. NAC. PREV. CARDIOVASCULAR","aprobado":2026338844,"value":    2021804617 

                },
                  {"name":"ATENCIÓN INT. EN SALUD RESPIRATORIA - INERAM","aprobado":43151723264,"value":  43118512084 
                },
                  {"name":"ATENCIÓN DE POBLAC. CON ENFERM. INFECCIOSAS IMT","aprobado":32544128372,"value":  32496323801 
                },
                  {"name":"ATENCIÓN A LA POBLACIÓN CON ENFERMEDADES ONCOLÓGICAS-INCAN","aprobado":351742128125,"value":  354742128125 
                },
                  {"name":"ATENC. DE POBLAC. C/ ENFERM. ONCOLOG. DE CUELLO UTERINO","aprobado":1219697453,"value":  1214600409 
                },
                  {"name":"ATENC. INT. DEL QUEMADO CENQUER","aprobado":26302722636,"value":  26117996437 
                },
                  {"name":"ATEN. INT. EN EL CENT. NAC. DE CONTROL DE ADICCIONES","aprobado":9695223179,"value": 9690481291 
                },
                  {"name":"ATENCION A PACIENTES RENALES INST. NAC. DE NEFROLOGÍA","aprobado":11677155387,"value": 11671364468 
                },
                  {"name":"ATENCIÓN A LAS URGENCIAS MÉDICO, QUIRURGICAS - H . TRAUMA","aprobado":143931084864,"value": 158690922517 
                },
                  {"name":"SERVICIO DE PROVISIÓN DE LECHE MATERNA - BANCO DE LECH","aprobado":87372284,"value": 87372284 
                },
                  {"name":"ATENCIÓN A LA POBLACIÓN CON ENFERMEDAD MENTAL - H. PSIQUIÁTR","aprobado":32934753373,"value": 32847557297},
                  {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN MAT. INF. SAN PABLO","aprobado":58552851083,"value": 58524722636},
                  {"name":"ATENCIÓN INTEGRAL A POBLACIÓN MAT. INF. CRUZ ROJA PYA.","aprobado":23361284705,"value":23354380330},
                  {"name":"ATENCIÓN INTEGRAL A POBLACIÓN MAT. INF. STMA.TRINIDAD","aprobado":30623703444,"value": 30605269443},
                  {"name":"CAPACITACIONES, FORMACIÓN E INVESTIGACIÓN EN EL IMT","aprobado":235504370,"value": 235504370},
                  {"name":"CAPACITACIONES, FORMACIÓN E INVESTIGACIÓN EN EL H. NACIONAL","aprobado":1295704508,"value":1246588131},
                  {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL H. PEDIÁTRICO","aprobado":23900740845,"value": 23900740845},
                  {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL INERAN","aprobado":180090481,"value": 180090481},
                  {"name":"CAPACITACIÓN, FORMACIÓN E INVESTIGACIÓN EN EL CENQUER","aprobado":745348726,"value":740348726},
                ]
              },{ 
                "name": "SERVICIOS DE APOYO Y DIAGNÓSTICO P/ LA MEJORA EN LA ATENCIÓN" ,
                "children": [
                    {"name":"OBTENCIÓN, PRODUCCIÓN Y SUMINISTROS DE SANGRE","aprobado":  6500188428,"value": 6493244129 },
                    {"name":"HABILITACIÓN Y CONTROL DE LABORATORIOS DE ANÁLISIS CLÍNICOS","aprobado":16344856465,"value":16344675375},
                    {"name":"SERVICIOS LABORATORIALES","aprobado":    7025618152 ,"value": 7011495785},
                    {"name":"GESTIONES INTEGRADAS PARA LA PROVISIÓN DE BIOLÓGICOS","aprobado":190399109118,"value": 190365890969},
                    {"name":"INTERVENCIONES EN CASOS EMERG. EXTRAHOSPITALARIAS","aprobado":59316980535,"value": 59252174844},
                    {"name":"PROV. DE MEDICAMENTOS, INSUMOS, INST.Y EQUIPOS","aprobado":492826057540,"value": 632096120173},
                ]
            },
            { 
              "name": "AUMENTO DE LA COBERTURA EN LA ATENC. A ENFERM. ESPECIALES" ,
              "children": [
                  {"name":"PROMOCIÓN Y TRATAMIENTO DE FIBRÓSIS QUÍSTICA Y RETARDO MENTA","aprobado":584427801,"value": 582836227},
                  {"name":"MEDICAMENTOS PARA ENFERMEDADES LISOSOMALES","aprobado":6440000000,"value":6440000000},
                  {"name":"ATENCIÓN INTEGRAL A LA POB. CON ENFERMEDADES OCULARES","aprobado":3662259656,"value":3652440717},
                  {"name":"ATENCIÓN INTEGRAL DE POBLACIÓN CON ENFERMEDADES BUCALES","aprobado":4397466098,"value":4371133696},
                  {"name":"ENTREGA DE INSUMOS PARA PERSONAS CON OSTOMÍA","aprobado":2097600000,"value":2097600000},
                  {"name":"ASISTENCIA A LA POBLACIÓN (ANATOMÍA Y SALUD MENTAL)","aprobado":484826985,"value": 480808374},
                  {"name":"CAPACITACIONES DE RR.HH. DEL MSPYBS.","aprobado":5960514798,"value": 5913659290},
                  {"name":"ENTREGA DE MEDICAMENTOS, KIT DE PARTO Y ANTICONCEPTIVOS","aprobado":16210434870,"value":16200496033},
              ]
          },
          { 
            "name": "SEGURIDAD ALIMENTARIA NUTRICIONAL HUMANA MEJORADA" ,
            "children": [
                {"name":"VIGILANCIA ALIMENTARIA NUTRICIONAL","aprobado":22085169959,"value": 22079785799},
                {"name":"SENSIBILIZACIÓN EN TEMAS DE ALIMENTACIÓN Y NUTRICIÓN","aprobado":3913924211,"value": 3913924211},
                {"name":"ASISTENCIA ALIMENTARIA NUTRICIONAL","aprobado":102858928891,"value":102846238891},

            ]
        },
        { 
          "name": "MEJORA EN EL BIENESTAR SOCIAL P/ PER. EN SITUAC. DE RIESGO" ,
          "children": [
              {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN ADULTA MAYOR","aprobado":1913599016,"value":1911871348 },
              {"name":"ATENCIÓN INTEGRAL A LA POBLACIÓN EN SITUACIÓN DE RIESGO","aprobado":25494285385,"value": 25480683755},
              {"name":"REGISTRO Y ACREDITACIÓN DE ENTIDADES SIN FINES DE LUCRO","aprobado":960742374,"value": 959215707},
          ]
      },
      { 
        "name": "ACCESO A LOS SERV. DE AGUA POTABLE Y SANEAMIENTO AMBIENTAL" ,
        "children": [
            {"name":"GESTIÓN ADMINISTRATIVA","aprobado":46458721500,"value": 46238172441 },
            {"name":"CONSTRUCCIÓN SEMB. OPORT.- 480 SISTEMA DE AGUA Y SANEAM.","aprobado":3528327466,"value": 3504807149 },
            {"name":"SP ABAST DE AGUA  POT Y SAN BAS PEQ COM RURAL E INDI(FOCEM)","aprobado":12359746995,"value": 12352678725 },
    
            {"name":"CONSTRUCCIÓN SIST AGUAPOT Y SAN PEQ CIUD COM RURAL E INDIG.","aprobado":104806242103,"value": 104806242103 },
            {"name":"ACCIONES DE VIGILANCIA DE CALIDAD AMBIENTAL","aprobado":20485126104,"value": 20467618810 },
            
        ]
    }
              
             
              
          ]
},
{
"name":"Partidas no asignables a Programas",
"aprobado":"4565004915"  ,"value": 4523060115 
}]}}
,{resumen:[
      {
        nombre:"ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN",
        porcentaje:"41.38"
      }, {
        nombre:"EDUCACIÓN MEDIA INTEGRADA",
        porcentaje:" 0.010"
      },
      {
        nombre:"CALIDAD DEL APRENDIZAJE",
        porcentaje:"14.28"
      },
      {
        nombre:"CULMINACIÓN OPORTUNA DE ESTUDIOS",
        porcentaje:"44"
      },
]}
  ],
},
{
  desarrollo:[{
    data:{
      labels: ["Programa Central","Programa Sustantivo", "Partidas no asignables a Programas"],
      datasets: [
        {
          data: [   13, 87 ],
          backgroundColor: ["orange", "#FAFF0E",'blue'],
          hoverBackgroundColor: ["orange", "#FAFF0E",'blue']
        }
    ]
  }},
  {items: [
    "Acceso a unidades de salud familiar integrales y de calidad en comunidades",
    "Servicios hospitalarios para la reducción de muertes causadas por enfermedades",
    "Servicios de apoyo y diagnóstico para mejora en la atención  ",
    "Aumento de la cobertura en la atención y medicamentos a enfermedades especiales (físicas y mentales)",
    "Sensibilización, asistencia y vigilancia en materia de alimentación y nutrición",
    "Bienestar social y atención a adultos mayores y otras personas en situaciones de riesgo ",
    "Acceso a los servicios de agua potable y saneamiento ambiental en comunidades rurales e indígenas"
    
  ]},{
  datos:{
    nombre:"MINISTERIO DE DESARROLLO SOCIAL",
    slug:'/desarrollo',
    programas:[{
      nombre:"Programa Central",
      monto: "G. 63.000.224.308  "
    },{
      nombre:"Misional",
      monto:"G. 10.316.780.170",
      aprobado: 10290040170
    },{
      nombre:"Administrativo",
      monto:"G. 52.683.444.138",
      aprobado: 52555625618
    },
    {
      nombre:"Proyecto",
      monto:"G. 0 ",
      aprobado:10000
    },{
      nombre:"Programa sustantivo",
      monto:"G.461.872.756.263"
    },
    {
      nombre:"Proyecto.",
      monto:"G. 0",
      aprobado:0
    },{
      nombre:"Actividad",
      monto:"G. 461.872.756.263",
      aprobado: 461739627226
    },{
      nombre:"Partidas no asignables a Programas",
      monto:"G. 0",
      aprobado:0
    }
  ],
  presupuestoTotal: "G. 524.872.980.571",
  
  },
 
    
  },{
    treeMapData : {
      'name':"MINISTERIO DE DESARROLLO SOCIAL",
      'children':
      [{
      "name":"Programa Central",
      "children": [
                  { 
                      "name": "Actividades Administrativas" ,
                      "children": [
                          {"name":"ACTIVIDADES CENTRALES ADMINISTRATIVAS","value":  52683444138 , "aprobado": 52555625618  },
                          
                          
                      ]
                  },
                  {
                      "name":"Actividad Misional",
                      "children": [
                          {"name":"ATENCION SOCIAL Y COMEDORES COMUNITARIOS","value":  3457090000, "aprobado": 3441022000   },
                          {"name":"ASISTENCIA A PESCADORES POR VEDA PESQUERA","value":  6859690170, "aprobado": 6849018170    },
                          
                          
                              
                      ]
                  },
                 /*  {
                      "name":"Proyecto",
                      "children": [
                          {"name":"008-SP PROGRAMA DE DESARROLLO INFANTIL TEMPRANO (DIT)","value":  120322934005 },
                          {"name":"012-INVESTIGACIÓN ,EDUC. Y BIOTECNOLOGÍA APLICADAS A LA SALUD","value":8466618649},

                      ]
                  }, */
                 
              ]
  }
      ,{
    "name":"Programa Sustantivo",
    "children": [
                { 
                    "name": "PROTECCION SOCIAL A FAMILIAS EN SITUACION DE POBREZA" ,
                    "children": [
                        {"name":"PROTECCION SOCIAL A FAMILIAS DE TEKOPORA","value":415595076232, "aprobado": 415536264538   }
                    ]
                },{
                  "name":"INCLUSIÓN ECONÓMICA DE FAMILIAS EN SITUACIÓN DE POBREZA",
                  "children":[
                    {"name":"FOMENTO DE MICROEMPRENDIMIENTOS A PARTICIPANTES DE TENONDERA","value":  37132748096, "aprobado": 37086423172   },
                    
                ]
            },
            { 
              "name": "PROMOCION SOCIAL" ,
              "children": [
                  {"name":"REGULARIZACIÓN DE TERRITORIOS SOCIALES","value":9144931935, "aprobado": 9116939516  },
                  

              ]
          },
         
                
               
                
            ]
},
/* {
  "name":"Partidas no asignables",
  "value":"4565004915"    
} */]}},
{
  treeMapDataAprobado : {
    'name':"MINISTERIO DE DESARROLLO SOCIAL",
    'children':
    [{
    "name":"Programa Central",
    "children": [
                { 
                    "name": "Actividades Administrativas" ,
                    "children": [
                        {"name":"ACTIVIDADES CENTRALES ADMINISTRATIVAS","aprobado":  52683444138 , "value": 52555625618  },
                        
                        
                    ]
                },
                {
                    "name":"Actividad Misional",
                    "children": [
                        {"name":"ATENCION SOCIAL Y COMEDORES COMUNITARIOS","aprobado":  3457090000, "value": 3441022000   },
                        {"name":"ASISTENCIA A PESCADORES POR VEDA PESQUERA","aprobado":  6859690170, "value": 6849018170    },
                        
                        
                            
                    ]
                },
               /*  {
                    "name":"Proyecto",
                    "children": [
                        {"name":"008-SP PROGRAMA DE DESARROLLO INFANTIL TEMPRANO (DIT)","aprobado":  120322934005 },
                        {"name":"012-INVESTIGACIÓN ,EDUC. Y BIOTECNOLOGÍA APLICADAS A LA SALUD","aprobado":8466618649},

                    ]
                }, */
               
            ]
}
    ,{
  "name":"Programa Sustantivo",
  "children": [
              { 
                  "name": "PROTECCION SOCIAL A FAMILIAS EN SITUACION DE POBREZA" ,
                  "children": [
                      {"name":"PROTECCION SOCIAL A FAMILIAS DE TEKOPORA","aprobado":415595076232, "value": 415536264538   }
                  ]
              },{
                "name":"INCLUSIÓN ECONÓMICA DE FAMILIAS EN SITUACIÓN DE POBREZA",
                "children":[
                  {"name":"FOMENTO DE MICROEMPRENDIMIENTOS A PARTICIPANTES DE TENONDERA","aprobado":  37132748096, "value": 37086423172   },
                  
              ]
          },
          { 
            "name": "PROMOCION SOCIAL" ,
            "children": [
                {"name":"REGULARIZACIÓN DE TERRITORIOS SOCIALES","aprobado":9144931935, "value": 9116939516  },
                

            ]
        },
       
              
             
              
          ]
},
/* {
"name":"Partidas no asignables",
"aprobado":"4565004915"    
} */]}}
,{resumen:[
      {
        nombre:"ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN",
        porcentaje:"41.38"
      }, {
        nombre:"EDUCACIÓN MEDIA INTEGRADA",
        porcentaje:" 0.010"
      },
      {
        nombre:"CALIDAD DEL APRENDIZAJE",
        porcentaje:"14.28"
      },
      {
        nombre:"CULMINACIÓN OPORTUNA DE ESTUDIOS",
        porcentaje:"44"
      },
]}
  ],
}]);


export default ministerioContext;






// OOOOOOLAAAA //

/* {
  treeMapData : {
    'name':"MINISTERIO DE EDUCACIÓN Y CIENCIAS",
    'children':
    [{
    "name":"Programa Central",
    "children": [
                { 
                    "name": "Actividade Administrativa" ,
                    "children": [
                        {"name":"GESTIÓN ADMINISTRATIVA INSTITUCIONAL","value":  825610338799 },
                        
                    ]
                },
                {
                    "name":"Actividad Misional",
                    "children": [
                        {"name":"BRINDAR SERVICIO EDUCATIVO EN EDUCACIÓN INICIAL","value":  23733554561},
                        {"name":"BRINDAR SERVICIO EDUCATIVO EN PRIMER Y SEGUNDO CICLO DE EEB","value":  3788337220084 },
                        {"name":"BRINDAR SERVICIO EDUCATIVO EN EL TERCER CICLO DE LA EEB Y EM","value":  1446246278653 },
                        {"name":"ATENCION A EDUCACION SUPERIOR Y CIENCIAS","value":  55655897369},
                        {"name":"FORMACION DE MUSICOS EN LOS DISTINTOS GENEROS DE MUSICAL","value":  15378406129},
                        {"name":"BRINDAR SERV.EDUC.A JOVENES Y ADULTOS DE 15 AÑOS Y MAS","value":  335.486421492 },
                        {"name":"BRINDAR SERVICIO EDUCATIVO EN CENTROS REGIONALES DE EDUC","value":  80.117516114 },
                        {"name":"SERV.EDUC.A ESTUDIANTES C/NECESIDADES ESPECIFICAS DE APOYO","value":  18766116131},
                        {"name":"PROMOVER EL TALENTOY LA FORMACION ARTISTICA","value":  964526200 },
                        {"name":"BRINDAR ATENCION A INSTITUCIONES DE EDUC ESCOLAR INDIGENA","value":  4937942157},
                        {"name":"OTORGAR BECAS PARA EDUCACION SUPERIOR","value":  7837900000 },
                        {"name":"PLANIFICACION Y MONITOREO DE LA POLITICA EDUCATIVA NACIONAL","value":  377987871},
                        {"name":"DIFUNDIR LA INVESTIGACION EDUCATIVA","value":61750000},
                        {"name":"EVALUAR LA CALIDAD EDUCATIVA","value":3419909678},
                        {"name":"PROMOVER POLITICAS P/ EL DESARROLLO DE EDUCACION Y CIENCIAS","value":  2065696144}
                       

                        
                    ]
                },
                {
                    "name":"Proyecto",
                    "children": [
                        {"name":"FOMENTO Y APOYO OPERAT. PARA LA EJEC. DE PROY. DEL MEC","value":7840145062},  
                        {"name":"DIFUSIÓN DISEÑO DE LA ESTRATEGIA DE TRANSFORMACIÓN EDUC","value":  11635907321}, 
                    ]
                },
               
            ]
}
    ,{
  "name":"Programa Sustantivo",
  "children": [
              { 
                  "name": "001 - ATENCIÓN EDUCATIVA OPORTUNA AL PRE JARDIN Y JARDIN" ,
                  "children": [
                      {"name":"004-OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","value":9718004849},
                      {"name":"005-AMPLIACIÓN EN LA ATENC. EDUC. DESDE LA GEST. HASTA 5 AÑOS","value":123596276913},
                  ]
              },
              {
                  "name":"002 - EDUCACIÓN MEDIA INTEGRADA",
                  "children": [
                      {"name":"005-TRANSFERENCIA Y DOTACION TEXTOS A ESTUD.Y GUIAS A DOCENTES","value":32000000}
                      
                  ]
              },
              {
                  "name":"003 - CALIDAD DEL APRENDIZAJE",
                  "children": [
                      {"name":"001-ELABORAR DOCUMENTOS PEDAG. P/ ESTUD. C/ NECESIDADES DE APOYO","value":156753244},
                      {"name":"003-ELABORAR CURRÍCULUM Y MATERIAL EDUCATIVO P/ PUEBLO INDÍGENA","value":238349364},
                      {"name":"004-DOTAR A INSTITUCIONES EDUCATIVAS DE MATERIALES DIDÁCTICOS","value":1510309674},
                      {"name":"007-INVESTIGACIÓN EVAL. ESTANDARIZADAS DE DESEMPEÑO A ESTUD.","value":3520539526},
                      {"name":"008-CAPACITACIÓN APOYO A LA AMPLIACIÓN DE LA JORNADA ESCOLAR.","value":40569960659}
                      
                  ]
              },
              {
                  "name":"004 - CULMINACIÓN OPORTUNA DE ESTUDIOS",
                  "children": [
                      {"name":"004-OPTIMIZAR LOS PROGRAMAS COMPENSATORIOS","value":133384400639},
                      {"name":"008-MEJORAMIENTO CONDICIONES DE TRANSPORTE DE ESTUDIANTES","value":9481118126}
                  ]
              }
          ]
}]}}
 */

