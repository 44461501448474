import React, { Component, Fragment } from "react";

import { Container, Row, Col, Dropdown, Table } from "react-bootstrap";
import aprobacion from "../../assets/images/aprobacion.png";

import "./Aprobado.css";


import Informacion from "../../components/Informacion/Informacion";

import MinisterioContext from "../../context/ministerio_context";
import ContainerDimensions from "react-container-dimensions";
import Sunburst from "../../components/Sunburst/Sunburst";
import NumberFormat from "react-number-format";

import {
  scaleLinear as d3ScaleLinear,
  scaleSqrt as d3ScaleSqrt
} from "d3-scale";

import { hsl as d3Hsl } from "d3-color";

import down from "../../assets/icons/down.svg";
import up from "../../assets/icons/up.svg";
import equal from "../../assets/icons/equal.svg";

function addDots(nStr) {
  nStr += "";
  let x = nStr.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : "";
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "." + "$2"); // changed comma to dot here
  }
  return x1 + x2;
}

class Aprobado extends Component {
  state = {
    montosTotalesMinisterio:{
      titulo:null,
      ejecutivo:null,
      aprobado:null
    },
    nombreMinisterio: null,
    seleccionadoMinisterio: null,
    datosMinisterios: null,
    datosMultinivelMinisterio: null,
    dataMultinivel: null,
    data: [
      {
        labels: ["Ejecutivo", "Aprobado"],
        datasets: [
          {
            label: "Misional",
            backgroundColor: "#55A3EB",
            fillColor: "#000000",
            data: [0, 0]
          },
          {
            label: "Administrativo",
            backgroundColor: "#0AFC5D",
            fillColor: "#000000",
            data: [0, 0]
          },
          {
            label: "Proyecto",
            backgroundColor: "#E16163",
            fillColor: "#000000",
            data: [0, 0]
          }
        ]
      },
      {
        labels: ["Ejecutivo", "Aprobado"],
        datasets: [
          {
            label: "Proyecto.",
            backgroundColor: "#EB903D",
            fillColor: "#000000",
            data: [0, 0]
          },
          {
            label: "Actividad",
            backgroundColor: "#69FFED ",
            fillColor: "#000000",
            data: [0, 0]
          }
        ]
      },
      {
        labels: ["Ejecutivo", "Aprobado"],
        datasets: [
          {
            label: "Partidas no asignables a Programas",
            backgroundColor: "blue",
            fillColor: "#000000",
            data: [0, 0]
          }
        ]
      }
    ]
  };

  static contextType = MinisterioContext;

  index = 0;
  maxIndex = 11;
  colores = [
    { h: 209, s: 0.79, l: 0.63, opacity: 1 },
    { h: 175, s: 0.65, l: 0.5, opacity: 1 },
    { h: 70, s: 0.7, l: 0.68, opacity: 1 },
    { h: 24, s: 0.87, l: 0.62, opacity: 1 },
    { h: 359, s: 0.68, l: 0.63, opacity: 1 },
    { h: 340, s: 0.84, l: 0.62, opacity: 1 },
    { h: 44, s: 0.94, l: 0.61, opacity: 1 },
    { h: 288, s: 0.77, l: 0.7, opacity: 1 },
    { h: 240, s: 0.78, l: 0.77, opacity: 1 },
    { h: 71, s: 0.51, l: 0.5, opacity: 1 },
    { h: 326, s: 0.76, l: 0.74, opacity: 1 },
    { h: 241, s: 0.62, l: 0.6, opacity: 1 }
  ];

  componentDidMount() {
    const datosGraficos = this.context;
    let programasMinisterios = [];
    let datosMultinivelMinisterio = [];

    console.log(datosGraficos);

    datosGraficos.forEach(el => {
      const nombre = el[Object.keys(el)[0]][2].datos.nombre;
      const slug = el[Object.keys(el)[0]][2].datos.slug;
      const datosMultinivel = el[Object.keys(el)[0]][4].treeMapDataAprobado;

      const subprogramas = el[Object.keys(el)[0]][2].datos.programas

        .filter(item => item.ejecutivo !== undefined)
        .map(el => {
          return {
            nombre: el.nombre,
            monto:
              +el.monto
                .replace("G.", "")
                .split(".")
                .join("") / 1000000000000,
            ejecutivo: el.ejecutivo / 1000000000000
          };
        });
      console.log(subprogramas);
      let dato = {
        [nombre]: subprogramas
      };
      let datoGrafico = {
        [nombre]: datosMultinivel
      };
      programasMinisterios.push(dato);
      datosMultinivelMinisterio.push(datoGrafico);

      //programasMinisterios.push(el[Object.keys(el)[0]][2].datos.programas)
    });
    const dataMultinivel =
      datosMultinivelMinisterio[0][
        Object.keys(datosMultinivelMinisterio[0])[0]
      ];
    console.log(dataMultinivel);

    this.setState({
      datosMinisterios: programasMinisterios,
      datosMultinivelMinisterio,
      dataMultinivel
    });

    let ministerios = [
      { educacion: "Ministerio de Educacion" },
      { salud: "Ministerio de Salud" },
      { obra: "Ministerio de obras" },
      { desarrollo: "Ministerio de Desarrollo" }
    ];
    this.setState({ nombreMinisterio: ministerios });
  }

  formatData = subprogramas => {
    let data = this.state.data.map(el => {
      el.datasets = el.datasets.map(item => {
        subprogramas[0].map(res => {
          if (res.nombre == item.label) {
            console.log("res: ", res.nombre, " item");
            item.data = [res.monto, res.ejecutivo];
          }
        });
        return item;
      });
      return el;
    });
    console.log(data);
    return data;
  };

  handleClick = titulo => {
    console.log(titulo);
    let subprogramas;
    this.state.datosMinisterios.map(el => {
      if (el[titulo] != undefined) {
        subprogramas = [el[titulo]];
      }
    });
    let seleccionadoDatoMultinivel;
    this.state.datosMultinivelMinisterio.map(el => {
      if (Object.keys(el) == titulo) {
        seleccionadoDatoMultinivel = el[Object.keys(el)];
      }
    });
    const datosGrafico = this.formatData(subprogramas);
    //this.changeListHandler(seleccionadoDatoMultinivel)
    //  const seleccionadoMinisterio = {titulo:titul}
    this.setState({
      seleccionadoMinisterio: titulo,
      data: datosGrafico,
      dataMultinivel: seleccionadoDatoMultinivel
    });
  };

  changeListHandler = node => {
    console.log(node);
    let nodoNuevoTitulo = this.colorNodoLista(node);
    const titulo = {
      nombre: nodoNuevoTitulo.data.name,
      valor: nodoNuevoTitulo.value,
      color: nodoNuevoTitulo.color
    };
    this.index = 0;

    const lista = [];
    if (node.children) {
      let nodoNuevoHijo;
      node.children.map((valor, index) => {
        nodoNuevoHijo = this.colorNodoLista(valor);
        const elemento = {
          nombre: valor.data.name,
          valor: valor.value,
          ejecutivo: valor.aprobado,
          color: nodoNuevoHijo.color
        };
        console.log(elemento);
        lista[index] = elemento;
      });
    }
    this.index = 0;

    const estado = {
      titulo: titulo,
      lista: lista
    };
    //let montosTotalesMinisterio =null;
    console.log('[estado]',estado);
    console.log(estado.titulo.nombre.startsWith('MINISTERIO'))
    console.log(this.state.montosTotalesMinisterio != null  && this.state.montosTotalesMinisterio.titulo != estado.titulo.nombre && estado.titulo.nombre.startsWith('MINISTERIO'))

    if (this.state.montosTotalesMinisterio.titulo != estado.titulo.nombre && estado.titulo.nombre.startsWith('MINISTERIO') ){
      let sumaEjecutivo=0; 
      estado.lista.map(el => {
        sumaEjecutivo = sumaEjecutivo + el.ejecutivo
      } )
      
      const montosTotalesMinisterio = {
        titulo:estado.titulo.nombre,
        aprobado:estado.titulo.valor,
        ejecutivo:sumaEjecutivo
      }
      
      this.setState({montosTotalesMinisterio:montosTotalesMinisterio})

    }
      
    
    this.setState({ lista: estado });
  };

  colorNodoLista(nodo) {
    let hueDXScale = d3ScaleLinear()
      .domain([0, 1])
      .range([0, 360]);

    const hue = hueDXScale(nodo.x0);
    console.log("colorNodo");
    console.log(nodo.x0);
    const color = d3Hsl(hue, 0.9, 0.6);
    color.h = this.colores[this.index].h;
    color.s = this.colores[this.index].s;
    color.opacity = this.colores[this.index].opacity;
    color.l = this.colores[this.index].l;
    let nodoNuevo = { ...nodo, color: color };
    console.log(nodoNuevo);
    this.index++;
    if (this.index >= this.maxIndex) {
      console.log("EY", this.index);
      this.index = 0;
    }
    return nodoNuevo;
  }

  render() {
    const programas = [
      "Programas Centrales",
      "Programas Sustantivos",
      "Partidas no asignables a Programas"
    ];

    return (
      <Fragment>
        <Container>
          <Row className="bannerTop">
            <Col md={6}>
              <img
                className="bannerTopImg"
                src={aprobacion}
                alt="Presupuesto Aprobado"
              />
            </Col>
            <Col md={6} className="align-self-center">
              <h1 className="bannerTopTitle">Aprobado por el Congreso.</h1>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <p>
                Entre noviembre y diciembre se procede a la aprobación del
                presupuesto, una vez acordadas las modificaciones y cambios se
                procede a aprobar el presupuesto a través de una ley de la
                Nación.
              </p>
              <p>
                Hasta 20 días hábiles desde la sanción del la ley se realiza la
                promulgación del presupuesto dicha ley debe ser presentada al
                Poder Ejecutivo que deberá refrendar y publicar la "Ley que
                aprueba el Presupuesto General de la Nación"
              </p>
            </Col>
            <Col md={6}>
              <p>
                A partir del 1 de enero hasta el 31 de diciembre de cada año,
                los Organismos y Entidades del Sector Público proceden a
                ejecutar el Presupuesto aprobado.
              </p>
            </Col>
          </Row>

          <Row>
            <Col>
              <h3 className="text-center font-weight-bold py-3">
              Comparativo entre Proyecto Ejecutivo del PGN y Ley de

Presupuesto General de la Nación{" "}
              </h3>
              <Dropdown className="d-flex justify-content-end">
                <Dropdown.Toggle className="buttonPrimary ">
                  {this.state.seleccionadoMinisterio
                    ? this.state.seleccionadoMinisterio
                    : "Seleccionar Ministerio"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/*  {this.state.nombreMinisterio ? this.state.nombreMinisterio.map(el => 
              <Dropdown.Item> {el[Object.keys(el)]} </Dropdown.Item>
             ):null}   */}

                  {this.state.datosMinisterios
                    ? this.state.datosMinisterios.map(el => (
                        <Dropdown.Item
                          onClick={() => this.handleClick(Object.keys(el)[0])}
                        >
                          {" "}
                          {Object.keys(el)[0]
                            .split(" ")
                            .map(el => {
                              return (
                                el
                                  .toLowerCase()
                                  .charAt(0)
                                  .toUpperCase() + el.toLowerCase().substring(1)
                              );
                            })
                            .join(" ")}{" "}
                        </Dropdown.Item>
                      ))
                    : null}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
          <Row></Row>
        </Container>
        <Container>
          <Row className="py-5">
            <Col md={4}>
              <div className="d-flex align-items-center justify-content-center">
                {this.state.dataMultinivel && (
                  <ContainerDimensions>
                    {({ width, height }) => (
                      <Sunburst
                        changed={this.changeListHandler}
                        data={this.state.dataMultinivel}
                        width={width}
                        height="400"
                        count_member="value"
                        tooltipFunc={data => {
						  const value = addDots(data.value);
						  const res = `${data.data.name} 
						  G.\xa0${value} `;
						  return res
                        }}
                        _debug={false}
                      />
                    )}
                  </ContainerDimensions>
                )}
              </div>
            </Col>
            <Col className="" md={8}>
              <div>
                {this.state.lista && (
                  <Fragment>
                    <Table responsive="md">
                      <thead>
                        <tr>
                          <th width="30%" className="align-middle width-name">
                            Nombre
                          </th>
                          
                          <th width="30%" className="text-left align-middle width-ejecutivo">
                            Ejecutivo
                          </th>
                          <th width="30%" className="text-left align-middle width-aprobado">
                            Aprobado
                          </th>
                          <th width="10%" className="text-right align-middle width-porcentaje font-size-procentaje">
                            % de Participación del Presupuesto Aprobado
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.lista &&
                          this.state.lista.lista.map((item, index) => {
                            console.log("[aprobado]: ", item);
                            return (
                              <tr
                                style={{
                                  borderLeft: `10px hsl(${item.color.h}, ${(
                                    item.color.s * 100
                                  ).toFixed(2) + "%"}, ${(
                                    item.color.l * 100
                                  ).toFixed(2) + "%"}) solid`
                                }}
                              >
                                <td width="10%" style={{fontSize:'0.75em'}} className="text-uppercase  align-middle  width-name">
                                  {item.nombre}
                                </td>
                                <td width="40%" style={{fontSize:'0.75em'}} className="text-left p-1  align-middle width-ejecutivo">
                                  <NumberFormat
                                    value={item.ejecutivo}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    prefix={"G.\xa0"}
                                  />{" "}
                                </td>
                                <td width="40%" style={{fontSize:'0.75em'}} className="text-left p-1  align-middle width-aprobado">
                                  <NumberFormat
                                    value={item.valor}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    prefix={"G.\xa0"}
                                  />{" "}
                                </td>
                             
                                <td width="5%" style={{fontSize:'0.75em'}} className="text-right p-1  align-middle width-porcentaje">
                                  <NumberFormat
                                    value={
                                      (item.valor /
                                        this.state.lista.titulo.valor) *
                                      100
                                    }
                                    displayType={"text"}
                                    decimalScale={2}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    suffix={"%"}
                                  ></NumberFormat>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    {this.state.montosTotalesMinisterio && 
                      <Table responsive="md">
                      <tr>
                         <td style={{fontSize:'0.75em'}} class="width-name">
                            Total de Entidad:
                          </td>
                          <td style={{fontSize:'0.75em'}} class=" text-left p-1  align-middle  width-ejecutivo" >
                             <NumberFormat
                                    value={this.state.montosTotalesMinisterio.ejecutivo}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    prefix={"G.\xa0"}
                                  />{" "}</td>
                          <td style={{fontSize:'0.75em'}} class="text-left p-1  align-middle  width-aprobado">
                          <NumberFormat
                                    value= {this.state.montosTotalesMinisterio.aprobado}
                                    displayType={"text"}
                                    decimalSeparator={","}
                                    thousandSeparator={"."}
                                    prefix={"G.\xa0"}
                                  />{" "}
                           </td>
                          <td style={{fontSize:'0.75em'}} class="text-right p-1  align-middle width-porcentaje">100%</td>
                      </tr>
                         

                    </Table>
                    }
                    

                  </Fragment>
                )}
              </div>
            </Col>
          </Row>
        </Container>

        <Informacion />
      </Fragment>
    );
  }
}

export default Aprobado;
